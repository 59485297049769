/* General */
import * as React from 'react'
import { useContext, forwardRef } from 'react'
import { StoreContext } from 'stores/store'
import { observer } from 'mobx-react'
import { jsPDF } from 'jspdf'

/* Components */
import { Tooltip } from '@mui/material'
import { LoadingButton } from '@mui/lab'

/* Help */
import { isEmpty } from 'lodash'
import { rgbToHex } from 'utils/help'
import simplify from 'simplify-geojson'

/* Icons */
import PrintIcon from '@mui/icons-material/Print'

const MapPrinting = forwardRef(function MapPrinting (props, ref) {
  const store = useContext(StoreContext)

  const primaryR = parseInt(store.configuration.properties.color.red)
  const primaryG = parseInt(store.configuration.properties.color.green)
  const primaryB = parseInt(store.configuration.properties.color.blue)

  const textR = 45
  const textG = 46
  const textB = 46

  const primaryColorHex = rgbToHex(primaryR, primaryG, primaryB)

  const pdfWidth = 297
  const pdfHeight = 210

  const infoBoxWidth = pdfWidth / (1 + 1.618)
  const infoBoxX = 5
  const infoBoxY = 5

  const fontSize = 11
  const titleFontSize = fontSize + 2
  const watermarkFontSize = 11
  const watermarkText = 'Hautakartta.fi'

  const lineHeight = fontSize * 0.4
  const lineHeightWithIndex = lineHeight + 1.2
  const lineHeightNoIndex = lineHeight - 0.2

  const edgeRounding = 1
  const boxOpacity = 0.8

  const pathOpacity = 0.85
  const pathWidth = 10

  const buildGeoJsonRoute = (coordinates) => {
    return {
      type: 'Feature',
      properties: {
        stroke: primaryColorHex,
        'stroke-opacity': pathOpacity,
        'stroke-width': pathWidth
      },
      geometry: {
        type: 'LineString',
        coordinates: coordinates
      }
    }
  }

  const buildPins = (waypoints) => {
    if (waypoints.length === 1) return `,pin-l+555555(${waypoints[0].location.lng},${waypoints[0].location.lat})`

    const pins = waypoints.map((waypoint, idx) =>
      `pin-l-${idx + 1}+555555(${parseFloat(waypoint.location.lng.toFixed(5))},${parseFloat(waypoint.location.lat.toFixed(5))})`
    )
    return ',' + pins.join(',')
  }

  const buildInfoBoxText = () => {
    let infoBoxText = ''
    const themeRouteSelected = store.themeRoute.selected
    const graveRouteSelected = store.grave.selected

    if (!isEmpty(themeRouteSelected)) {
      const { waypoints } = themeRouteSelected
      infoBoxText = waypoints.map((waypoint, idx) => {
        const indexSpacing = (idx + 1) < 10 ? '   ' : ' '
        return `${idx + 1}.${indexSpacing}${waypoint.title}`
      }).join('\n')
      infoBoxText = `${themeRouteSelected.title}\n${infoBoxText}`
    }

    if (!isEmpty(graveRouteSelected)) {
      const dateOfBirthYear = new Date(graveRouteSelected.dateOfBirth).getFullYear()
      const dateOfDeathYear = new Date(graveRouteSelected.dateOfDeath).getFullYear()
      const graveId = graveRouteSelected.graveId
      infoBoxText = `${graveRouteSelected.name}\n(${dateOfBirthYear} - ${dateOfDeathYear})\n\n${graveId}`
    }

    return infoBoxText
  }

  const cropAndRoundImage = (img, cropAmount, round) => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.width = img.width
    canvas.height = img.height

    if (round) {
      context.beginPath()
      const roundAmount = 5
      context.moveTo(roundAmount, 0)
      context.lineTo(canvas.width - roundAmount, 0)
      context.quadraticCurveTo(canvas.width, 0, canvas.width, roundAmount)
      context.lineTo(canvas.width, canvas.height - roundAmount)
      context.quadraticCurveTo(canvas.width, canvas.height, canvas.width - roundAmount, canvas.height)
      context.lineTo(roundAmount, canvas.height)
      context.quadraticCurveTo(0, canvas.height, 0, canvas.height - roundAmount)
      context.lineTo(0, roundAmount)
      context.quadraticCurveTo(0, 0, roundAmount, 0)
      context.clip()
    }

    context.drawImage(img, cropAmount / 2, 0, img.width - cropAmount, img.height - cropAmount, 0, 0, canvas.width, canvas.height)

    return canvas.toDataURL('image/png')
  }

  const addZoomedGrave = (doc, infoBoxHeight) => {
    const { selected } = store.grave

    const zoomedGraveHeight = 75
    const zoomedGraveWidth = 103
    const fetchedImgHeight = 160
    const fetchedImgWidth = Math.round((zoomedGraveWidth / zoomedGraveHeight) * fetchedImgHeight)

    const zoomedGraveX = infoBoxX + (infoBoxWidth - zoomedGraveWidth) / 2
    const zoomedGraveY = infoBoxY + infoBoxHeight - zoomedGraveHeight - 13
    store.printing.basemapSuccess = false

    return new Promise((resolve, reject) => {
      store.printing.fetchBasemap({
        longitude: selected.location.lng,
        latitude: selected.location.lat,
        zoom: 17,
        route: store.printing.overlay,
        width: fetchedImgWidth,
        height: fetchedImgHeight
      }).then(() => {
        if (store.printing.basemapSuccess) {
          const img = new Image()
          img.src = store.printing.basemapImgUrl
          img.onload = () => {
            const croppedAndRoundedImage = cropAndRoundImage(img, 30, true)
            doc.saveGraphicsState()
            doc.setGState(new doc.GState({ opacity: 1 }))
            doc.addImage(croppedAndRoundedImage, 'PNG', zoomedGraveX, zoomedGraveY, zoomedGraveWidth, zoomedGraveHeight)
            doc.restoreGraphicsState()

            resolve(doc)
          }
          img.onerror = reject
        } else {
          resolve(doc)
        }
      }).catch(reject)
    })
  }

  // this watermark is added in case of no infobox is present
  const addWatermark = (doc) => {
    const watermarkTextX = 1
    const watermarkTextY = 3.5

    const watermarkBoxX = 0
    const watermarkBoxY = 0
    const watermarkBoxWidth = 34
    const watermarkBoxHeight = 5

    doc.saveGraphicsState()
    doc.setFont('courier', 'bold')
    doc.setFontSize(watermarkFontSize)
    doc.setTextColor(textR, textG, textB)
    doc.setFillColor(230, 228, 224)
    doc.setDrawColor(230, 228, 224)
    doc.setGState(new doc.GState({ opacity: boxOpacity }))

    doc.roundedRect(watermarkBoxX, watermarkBoxY, watermarkBoxWidth, watermarkBoxHeight, edgeRounding, edgeRounding, 'FD')
    doc.text(watermarkText, watermarkTextX, watermarkTextY)
    doc.restoreGraphicsState()

    return doc
  }

  const addWatermarkToBox = (doc, boxX, boxY, boxWidth, boxHeight) => {
    const margin = 2

    doc.saveGraphicsState()
    doc.setFont('courier', 'bold')
    doc.setFontSize(watermarkFontSize)
    doc.setTextColor(textR, textG, textB)

    const textWidth = doc.getTextWidth(watermarkText)
    const textX = (boxX + boxWidth) - textWidth - margin
    const textY = (boxY + boxHeight) - margin

    doc.text(watermarkText, textX, textY)
    doc.restoreGraphicsState()

    return doc
  }

  const isFirstLine = (line) => {
    return /^\d+\./.test(line)
  }

  const processLines = (lines) => {
    const processedLines = []
    const spacing = '      '

    if (isEmpty(store.themeRoute.selected)) {
      return lines
    }

    lines.forEach(line => {
      isFirstLine(line)
        ? processedLines.push(`${line}`)
        : processedLines.push(`${spacing}${line}`)
    })

    return processedLines
  }

  const addLinesToInfoBox = (lines, linesX, infoboxY, doc) => {
    const graveSelected = !isEmpty(store.grave.selected.graveId)
    let lineY = graveSelected ? infoboxY + 17 : infoboxY + 23

    lines.forEach((line, index) => {
      if (isFirstLine(line)) {
        if (index !== 0) {
          lineY += lineHeightWithIndex
        }
      } else {
        lineY += lineHeightNoIndex
      }
      if (graveSelected) {
        doc.saveGraphicsState()
        doc.setFontSize(10)

        doc.text(line, linesX, lineY, 'center')
        doc.restoreGraphicsState()
      } else {
        doc.text(line, linesX, lineY)
      }
    })
  }

  const calculateInfoBoxHeight = (lines) => {
    let totalHeight = 31

    lines.forEach((line) => {
      isFirstLine(line)
        ? totalHeight += lineHeightWithIndex
        : totalHeight += lineHeightNoIndex
    })

    return totalHeight
  }

  const addInfoBox = async (doc, height) => {
    const infoBoxPadding = 40
    const graveAdjustment = 75
    const lineWidthAdjustment = 0.5
    const titleYAdjustment = 10

    const infoBoxText = buildInfoBoxText()
    const middleX = infoBoxX + infoBoxWidth / 2

    doc.setFontSize(fontSize)

    const lines = doc.splitTextToSize(infoBoxText, infoBoxWidth - infoBoxPadding)
    const linesNoTitle = processLines(lines.slice(1))
    const title = lines[0]

    const longestLine = Math.max(...linesNoTitle.map(line => doc.getTextWidth(line)))
    const averageLineWidth = linesNoTitle.reduce((acc, line) => acc + doc.getTextWidth(line), 0) / linesNoTitle.length
    const definiteLineWidth = (longestLine + averageLineWidth) / 2

    const linesX = !isEmpty(store.grave.selected.graveId) ? middleX : middleX - definiteLineWidth / 2
    let infoBoxHeight = calculateInfoBoxHeight(lines)

    infoBoxHeight = Math.min(infoBoxHeight, height - 2 * infoBoxY)
    infoBoxHeight = !isEmpty(store.grave.selected.graveId) ? infoBoxHeight + graveAdjustment : infoBoxHeight

    doc.saveGraphicsState()
    doc.setFillColor(255, 255, 255)
    doc.setDrawColor(textR, textG, textB)
    doc.setGState(new doc.GState({ opacity: boxOpacity }))

    doc.roundedRect(infoBoxX, infoBoxY, infoBoxWidth, infoBoxHeight, edgeRounding, edgeRounding, 'FD')
    doc.restoreGraphicsState()

    doc.saveGraphicsState()
    doc.setTextColor(textR, textG, textB)
    doc.setFontSize(titleFontSize)
    doc.setFont('helvetica', 'bold')
    doc.setLineWidth(lineWidthAdjustment)

    const titleWidth = doc.getTextWidth(`${title}`)
    const separationLineX = middleX - titleWidth / 2
    const separationLineY = infoBoxY + titleYAdjustment + lineHeight

    doc.text(title, middleX, infoBoxY + titleYAdjustment, 'center')
    doc.line(separationLineX, separationLineY, separationLineX + titleWidth, separationLineY)
    doc.restoreGraphicsState()

    addLinesToInfoBox(linesNoTitle, linesX, infoBoxY, doc)

    !isEmpty(store.grave.selected.graveId) && await addZoomedGrave(doc, infoBoxHeight)

    addWatermarkToBox(doc, infoBoxX, infoBoxY, infoBoxWidth, infoBoxHeight)

    return doc
  }

  const generateLinkLines = (waypoint) => {
    const linksArray = []

    waypoint.link1Description && linksArray.push(`\n\n${waypoint.link1Description}`)
    waypoint.link1 && linksArray.push(`\n${waypoint.link1}`)

    waypoint.link2Description && linksArray.push(`\n\n${waypoint.link2Description}`)
    waypoint.link2 && linksArray.push(`\n${waypoint.link2}`)

    waypoint.link3Description && linksArray.push(`\n\n${waypoint.link3Description}`)
    waypoint.link3 && linksArray.push(`\n${waypoint.link3}`)

    return linksArray.join('')
  }

  const addDescBoxBodyText = (bodyText, textX, textY, doc) => {
    // TODO: Remove this horrible regular expression!
    const urlRegex = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/=~_|$?!:,.]*\)|[-A-Z0-9+&@#/=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/=~_|$?!:,.]*\)|[A-Z0-9+&@#/=~_|$])/igm
    const isLink = (line) => urlRegex.test(line)

    let currentY = textY

    bodyText.forEach((line) => {
      doc.saveGraphicsState()

      if (isLink(line)) {
        doc.setTextColor(0, 0, 238)
        doc.textWithLink(line, textX, currentY, { url: line })
      } else {
        doc.text(line, textX, currentY)
      }

      doc.restoreGraphicsState()

      currentY += lineHeight
    })
  }

  // TODO: Rewrite this function at some point
  const addDescriptionPages = async (doc) => {
    const boxX = 20
    let boxY = 10
    const margin = 5
    const boxWidth = pdfWidth - 2 * boxX

    const { selected } = store.themeRoute

    const routeData = [
      {
        title: selected.title,
        description: selected.description || ''
      },
      ...selected.waypoints
    ]

    doc.addPage()
    let isFirstBox = true

    routeData.forEach((waypoint, index) => {
      let titleLines = doc.splitTextToSize(waypoint.title, boxWidth - 2 * margin)
      const titleLinesLength = titleLines.length
      const bodyText = `${waypoint.description.replace(/\n/g, '\n\n')}${generateLinkLines(waypoint)}`
      const splitBodyText = doc.splitTextToSize(bodyText, boxWidth - 2 * margin)

      const boxHeight = isEmpty(waypoint.description) ? 16 : 24 + (splitBodyText.length) * lineHeight + margin

      if (!boxY || boxY + boxHeight + margin > pdfHeight) {
        doc.addPage()
        boxY = 10
      }

      index !== 0 && (titleLines = `${index}. ${titleLines}`)

      doc.saveGraphicsState()
      doc.setFillColor(255, 255, 255)
      doc.setLineWidth(0.1)

      doc.roundedRect(boxX, boxY, boxWidth, boxHeight, edgeRounding, edgeRounding, 'FD')
      doc.restoreGraphicsState()

      doc.saveGraphicsState()
      doc.setFontSize(titleFontSize)
      doc.setFont('helvetica', 'bold')

      doc.text(titleLines, boxX + margin, boxY + margin + lineHeight)
      doc.restoreGraphicsState()

      addDescBoxBodyText(splitBodyText, boxX + margin, boxY + margin + lineHeight * (titleLinesLength + 2), doc)

      // eslint-disable-next-line no-unused-expressions
      isFirstBox &&
        (addWatermarkToBox(doc, boxX, boxY, boxWidth, boxHeight),
        isFirstBox = false)

      boxY += boxHeight + margin
    })

    return doc
  }

  const generatePdfAndSave = () => {
    const pdfName = 'hautakartta.pdf'
    const img = new Image()
    img.src = store.printing.basemapImgUrl
    img.onload = function () {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: 'a4'
      })

      const basemapAspectRatio = img.width / img.height

      const basemapWidth = pdfWidth
      const basemapHeight = pdfWidth / basemapAspectRatio
      const basemapX = (pdfWidth - basemapWidth) / 2
      const basemapY = (pdfHeight - basemapHeight) / 2

      doc.addImage(img, 'PNG', basemapX, basemapY, basemapWidth, basemapHeight)

      const themeRouteSelected = !isEmpty(store.themeRoute.selected)
      const graveSelected = store.grave.selected && !isEmpty(store.grave.selected.graveId)

      if (graveSelected || themeRouteSelected) {
        addInfoBox(doc, pdfHeight).then((doc) => {
          if (themeRouteSelected) {
            addDescriptionPages(doc).then((doc) => {
              doc.save(pdfName)
              store.printing.fetching = false
            })
          } else {
            doc.save(pdfName)
            store.printing.fetching = false
          }
        })
      } else {
        addWatermark(doc)
        doc.save(pdfName)
        store.printing.fetching = false
      }
    }
  }

  const downloadMap = async () => {
    store.printing.fetching = true
    const themeRoute = store.themeRoute.selected
    const grave = store.grave.selected
    const cemetery = store.cemetery.list.find(
      ({ id }) => id === themeRoute?.cemeteryId || id === grave?.cemeteryId
    )

    const geoJsonSource = ref.current.getSource('route')
    const geoJsonCoords = geoJsonSource?._data?.geometry?.coordinates || []

    const roundedCoordinates = geoJsonCoords.map(coord => [parseFloat(coord[0].toFixed(5)), parseFloat(coord[1].toFixed(5))])
    const geoJsonRoute = buildGeoJsonRoute(roundedCoordinates)
    const simplifiedGeoJsonCoords = simplify(geoJsonRoute, 0.0001)

    let pins = ''
    let overlay = ''

    if (!isEmpty(cemetery)) {
      switch (store.navigation.tab) {
        case 'tab-search':
          pins = buildPins([grave])
          geoJsonCoords.length === 0 && (overlay = `/pin-l+555555(${grave.location.lng},${grave.location.lat})`)
          break
        case 'tab-theme-routes':
          pins = buildPins(themeRoute?.waypoints)
          break
        default:
          break
      }
    }

    !(geoJsonCoords.length === 0) && (overlay = `/geojson(${encodeURIComponent(JSON.stringify(simplifiedGeoJsonCoords))})${pins}`)
    store.printing.overlay = overlay

    const currBounds = ref.current.getBounds()
    const southWest = cemetery?.location.southWest || [currBounds._sw.lng, currBounds._sw.lat]
    const northEast = cemetery?.location.northEast || [currBounds._ne.lng, currBounds._ne.lat]

    const bbox = `[${southWest[0]},${southWest[1]},${northEast[0]},${northEast[1]}]`

    await store.printing.fetchBasemap({
      bbox: bbox,
      route: overlay,
      width: 1280,
      height: 905
    })

    generatePdfAndSave()
  }

  return (
    <>
      {store.navigation.device === 'desktop' &&
        <Tooltip placement="left" title={store.localization.language.common.print}>
          <LoadingButton
            sx={store.styles.list.printMapButtonDesktop}
            color="primary"
            variant="contained"
            onClick={downloadMap}
            loading={store.printing.fetching}
          >
            <PrintIcon />
          </LoadingButton>
        </Tooltip>
      }
    </>
  )
})

export default observer(MapPrinting)
