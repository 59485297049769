import { fetchData } from 'utils/request-handler'

const StoreThemeRoute = (rootStore) => {
  return {
    listData: [],
    set list (value) {
      this.listData = value
    },
    get list () {
      return this.listData
    },

    fetchingData: false,
    set fetching (value) {
      this.fetchingData = value
    },
    get fetching () {
      return this.fetchingData
    },

    waypointIdxData: null,
    set waypointIdx (value) {
      this.waypointIdxData = value
    },
    get waypointIdx () {
      return this.waypointIdxData
    },

    showWaypointInfoData: false,
    set showWaypointInfo (value) {
      this.showWaypointInfoData = value
    },
    get showWaypointInfo () {
      return this.showWaypointInfoData
    },

    selectedData: {},
    set selected (value) {
      this.selectedData = value
    },
    get selected () {
      return this.selectedData
    },

    selectedWaypointData: null,
    set selectedWaypoint (value) {
      this.selectedWaypointData = value
    },
    get selectedWaypoint () {
      return this.selectedWaypointData
    },

    fetchList: async function (cemeteryId) {
      try {
        this.fetching = true
        const response = await fetchData(`${window.location.pathname}/api/themeroute/list/${cemeteryId}`)
        this.fetching = false
        if (response.success) {
          this.list = response.data
        }
      } catch (error) {
        console.error(error)
      }
    },

    fetch: async function (id) {
      try {
        this.fetching = true
        const response = await fetchData(`${window.location.pathname}/api/themeroute/id/${id}`)
        this.fetching = false
        if (response.success) {
          this.selected = response.data
          this.selected.waypoints.map((waypoint, idx) => {
            waypoint.idx = idx
            return waypoint
          })
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}

export default StoreThemeRoute
