/* General */
import * as React from 'react'
import { useContext, useEffect } from 'react'
import { StoreContext } from 'stores/store'
import { observer } from 'mobx-react'

/* Components */
import {
  Grid,
  FormControl,
  NativeSelect,
  InputLabel,
  Typography,
  Box,
  CircularProgress
} from '@mui/material'

/* Custom Components */
import TableScroll from 'components/navigation/table-scroll'

/* Help */
import { isEmpty } from 'lodash'

const TabThemeRoute = () => {
  /* Variables */
  const store = useContext(StoreContext)
  const cemeteryString = store.configuration.properties.themeRouteCemeteries
  const cemeteries = cemeteryString ? cemeteryString.split(',') : []

  /* Initialize */
  useEffect(() => {
    if (store.cemetery.list.length === 0) {
      store.cemetery.fetch()
    }

    if (cemeteries.length === 1) {
      store.cemetery.selected = store.cemetery.list.find(g => g.id === cemeteries[0])
      store.themeRoute.fetchList(cemeteries[0])
    }
  }, [])

  /* Functions */
  const changeCemeteryAndFetch = async (event) => {
    const cemeteryId = event.target.value
    store.themeRoute.selected = {}
    if (!isEmpty(cemeteryId)) {
      store.cemetery.selected = store.cemetery.list.find(g => g.id === cemeteryId)
      store.themeRoute.fetchList(cemeteryId)
    } else {
      store.setThemeRoutesList([])
      store.map.render()
    }
  }

  const selectThemeRoute = async (event) => {
    store.map.focus = true
    await store.themeRoute.fetch(event.target.value)
    store.themeRoute.showWaypointInfo = false
    store.themeRoute.waypointIdx = null
    store.themeRoute.selectedWaypoint = null
    store.map.render()
  }

  const selectForm = () => {
    return (
      <>
        <Grid item xs={12}>
          {cemeteries.length > 1 &&
            <FormControl sx={store.styles.list.themeRouteCemeterySelect}>
              <InputLabel htmlFor="cemetery">{store.localization.language.search.cemetery}</InputLabel>
              <NativeSelect
                id="cemetery"
                value={store.cemetery.selected ? store.cemetery.selected.id : ''}
                onChange={changeCemeteryAndFetch}
              >
                <option value={null}></option>
                {store.cemetery.list.filter((g) => cemeteries.includes(g.id)).map((cemetery, idx) => {
                  return <option key={idx} value={cemetery.id}>{cemetery.title}</option>
                })}
              </NativeSelect>
            </FormControl>
          }
          {cemeteries.length === 1 && store.cemetery.list.length > 0 &&
            <Typography sx={store.styles.list.themeRouteTitle} varitant="button">{store.cemetery.list.find(g => g.id === cemeteries[0]).title ?? ''}</Typography>
          }
        </Grid>
        <Grid item xs={12}>
          {store.themeRoute.list.length > 0 &&
            <FormControl sx={store.styles.list.themeRouteSelect}>
              <InputLabel htmlFor="themeRoute">{store.localization.language.themeRoutes.label}</InputLabel>
              <NativeSelect id="themeRoute" value={store.themeRoute.selected.id} onChange={selectThemeRoute}>
                <option value={null}></option>
                {store.themeRoute.list.map(route => <option key={route.id} value={route.id}>{route.title}</option>)}
              </NativeSelect>
            </FormControl>
          }
        </Grid>
      </>
    )
  }

  return (
    <Grid container sx={store.styles.list.themeRouteContainer}>
      {selectForm()}
      <Grid item xs={12}>
        {!isEmpty(store.themeRoute.selected) &&
          <TableScroll type="themeRoute" />
        }
        {store.themeRoute.fetching &&
          <Box sx={store.styles.list.searchInfoSpinner}>
            <CircularProgress size={64} />
          </Box>
        }
      </Grid>
    </Grid>
  )
}

export default observer(TabThemeRoute)
