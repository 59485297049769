/* General */
import * as React from 'react'
import { useContext } from 'react'
import { StoreContext } from 'stores/store'
import { observer } from 'mobx-react'

/* Components */
import {
  Box,
  Typography
} from '@mui/material'

const HelpPageAccessibility = (props) => {
  const store = useContext(StoreContext)

  return (
    <Box sx={store.styles.list.accessibilityContainer}>
      <Box sx={store.styles.list.accessibilityTextContainer}>
        <Typography
          variant={'h7'}
          sx={store.styles.list.helpHeader}
        >
          {store.localization.accessibilityStatement.updatedLabel}{store.localization.accessibilityStatement.updated}
        </Typography>
        <Box sx={store.styles.list.helpTextContainer}>
          <Typography
            paragraph
          >
            {store.localization.accessibilityStatement.law}
          </Typography>
        </Box>
        <Typography
          variant={'h7'}
          sx={store.styles.list.helpHeader}
        >
          {store.localization.accessibilityStatement.evaluatorLabel}
        </Typography>
        <Box sx={store.styles.list.helpTextContainer}>
          <Typography
            paragraph
          >
            <Typography>
              {store.localization.accessibilityStatement.evaluator}
            </Typography>
            <Typography>
              {store.localization.accessibilityStatement.evaluation}
            </Typography>
          </Typography>
        </Box>
        <Typography
          variant={'h7'}
          sx={store.styles.list.helpHeader}
        >
          {store.localization.accessibilityStatement.contactLabel}
        </Typography>
        <Box sx={store.styles.list.helpTextContainer}>
          <Typography
            paragraph
          >
            {store.localization.accessibilityStatement.contact}
          </Typography>
        </Box>
        <Typography
          variant={'h7'}
          sx={store.styles.list.helpHeader}
        >
          {store.localization.accessibilityStatement.supervisingAuthorityLabel}
        </Typography>
        <Box sx={store.styles.list.helpTextContainer}>
          <Typography
            paragraph
          >
            {store.localization.accessibilityStatement.supervisingAuthorityInfo}
          </Typography>
          <Typography
            paragraph
          >
            <Typography>
              {store.localization.accessibilityStatement.supervisingAuthorityName}
            </Typography>
            <Typography>
              {store.localization.accessibilityStatement.supervisingAuthorityWeb}
            </Typography>
            <Typography>
              {store.localization.accessibilityStatement.supervisingAuthorityEmail}
            </Typography>
            <Typography>
              {store.localization.accessibilityStatement.supervisingAuthorityPhone}
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default observer(HelpPageAccessibility)
