/* General */
import * as React from 'react'
import { useContext } from 'react'
import { StoreContext } from 'stores/store'
import { observer } from 'mobx-react'

/* Custom Components */
import Header from 'components/navigation/header'
import Body from 'components/navigation/body'
import Footer from 'components/navigation/footer'

/* Appearance */
import { ThemeProvider } from '@mui/material/styles'

const PageMain = () => {
  const store = useContext(StoreContext)

  return (
    <ThemeProvider theme={store.customization.getTheme()}>
      <Header />
      <Body />
      <Footer />
    </ThemeProvider>
  )
}

export default observer(PageMain)
