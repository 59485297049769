import { postData } from 'utils/request-handler'

const StoreFeedback = (rootStore) => {
  return {
    sendingEmailData: false,
    set sendingEmail (value) {
      this.sendingEmailData = value
    },
    get sendingEmail () {
      return this.sendingEmailData
    },

    emailSuccessData: false,
    set emailSuccess (value) {
      this.emailSuccessData = value
    },
    get emailSuccess () {
      return this.emailSuccessData
    },

    sendEmail: async function (params) {
      try {
        this.sendingEmail = true
        const response = await postData(`${window.location.pathname}/api/email`, params)
        this.emailSuccess = response.success
        this.sendingEmail = false
      } catch (error) {
        console.error(error)
      }
    }
  }
}

export default StoreFeedback
