/* General */
import * as React from 'react'
import { useRef, useEffect, useState, useContext } from 'react'
import { StoreContext } from 'stores/store'
import { observer } from 'mobx-react'

/* Components */
import {
  CircularProgress,
  Button,
  Tooltip,
  Box,
  Typography
} from '@mui/material'

/* Help */
import { isEmpty } from 'lodash'

/* Icons */
import MyLocationIcon from '@mui/icons-material/MyLocation'

/* Custom Icons */
import pinIcon from 'assets/pin-icon'
import pinCrossIcon from 'assets/pin-cross-icon'
import pinUserIcon from 'assets/pin-user-icon'

/* Other */
import MapPrinting from 'components/navigation/map-printing'

/* Mapbox */
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp'
mapboxgl.workerClass = MapboxWorker
mapboxgl.accessToken = `${process.env.REACT_APP_MAPBOX_API_KEY}`

const markers = []

const Map = () => {
  const [zoom, setZoom] = useState(15)
  const store = useContext(StoreContext)
  const [lng, setLng] = useState(store.configuration.properties.mapbox.start.lng)
  const [lat, setLat] = useState(store.configuration.properties.mapbox.start.lat)
  const maxZoom = 19

  const [maxBounds] = useState([
    [store.configuration.properties.mapbox.min.lng, store.configuration.properties.mapbox.min.lat],
    [store.configuration.properties.mapbox.max.lng, store.configuration.properties.mapbox.max.lat]
  ])

  const mapContainer = useRef(null)
  const map = useRef()

  const clearMap = () => {
    if (!isEmpty(map.current.getLayer('route'))) {
      map.current.removeLayer('route')
    }
    if (!isEmpty(map.current.getSource('route'))) {
      map.current.removeSource('route')
    }
    if (map.current.hasImage('direction_pattern')) {
      map.current.removeImage('direction_pattern')
    }
    if (markers.length > 0) {
      for (const currentMarker of markers) {
        currentMarker.remove()
      }
    }
  }

  const addMarker = (className, coordinates, idx = null) => {
    const pin = document.createElement('div')
    pin.className = className

    const r = store.configuration.properties.color.red
    const g = store.configuration.properties.color.green
    const b = store.configuration.properties.color.blue

    const newMarker = new mapboxgl.Marker(pin, { anchor: 'bottom' })
      .setLngLat(coordinates)
      .addTo(map.current)

    if (className === 'user-marker') {
      const userPin = document.createElement('div')
      userPin.className = className
      userPin.innerHTML = pinUserIcon(r, g, b)
      pin.appendChild(userPin)
    }

    if (className === 'info-marker') {
      const infoPin = document.createElement('div')
      infoPin.className = className
      infoPin.innerHTML = pinCrossIcon(r, g, b)
      pin.appendChild(infoPin)
    }

    if (className === 'important-sight-index') {
      const index = document.createTextNode(idx + 1)
      const indexContainer = document.createElement('span')
      indexContainer.appendChild(index)
      pin.appendChild(indexContainer)
    }

    if (className === 'important-sight-marker' && idx !== null) {
      const importantPin = document.createElement('div')
      importantPin.className = 'important-sight-marker'
      importantPin.innerHTML = pinIcon(64, 64, 64)
      pin.appendChild(importantPin)

      newMarker.getElement().addEventListener('click', () => {
        store.map.focus = true
        store.themeRoute.waypointIdx = idx
        store.themeRoute.showWaypointInfo = true
        store.themeRoute.selectedWaypoint = store.themeRoute.selected.waypoints[idx]
        store.map.render()
      })
    }

    if (className === 'important-sight-selected-marker' && store.themeRoute.showWaypointInfo) {
      const selectedPin = document.createElement('div')
      selectedPin.className = 'important-sight-selected-marker'
      selectedPin.innerHTML = pinIcon(r, g, b)
      pin.appendChild(selectedPin)
    }

    markers.push(newMarker)
  }

  const addRoute = (coordinates) => {
    map.current.addSource('route', {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: coordinates
        }
      }
    })

    if (!map.current.getLayer('route')) {
      map.current.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': store.customization.getPalette().primary.main,
          'line-width': 10,
          'line-opacity': 0.85
        }
      })
    }
  }

  const getUserLocation = () => {
    if (!isEmpty(store.map.userGeolocation)) {
      return [store.map.userGeolocation.lng, store.map.userGeolocation.lat]
    }
    return []
  }

  useEffect(() => {
    if (isEmpty(map.current)) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: store.configuration.properties.mapbox.style,
        center: [lng, lat],
        zoom: zoom,
        trackResize: true,
        maxBounds: maxBounds,
        maxZoom: maxZoom
      })
      map.current.dragRotate.disable()
      map.current.touchZoomRotate.disableRotation()
      map.current.addControl(new mapboxgl.NavigationControl())
      // TODO: Find a better way to center & resize GLJS map canvas at init.
      map.current.on('load', () => {
        map.current.resize()
        if (store.map.nearbyCemetery && !isEmpty(store.map.userGeolocation)) {
          map.current.flyTo({
            center: store.map.userGeolocation,
            essential: true
          })
        }
      })
    } else {
      map.current.on('move', () => {
        setLng(map.current.getCenter().lng.toFixed(4))
        setLat(map.current.getCenter().lat.toFixed(4))
        setZoom(map.current.getZoom().toFixed(2))
      })

      clearMap()

      /* USER GELOCATION */
      const userCoordinates = getUserLocation()
      if (userCoordinates[0] !== undefined && userCoordinates[1] !== undefined) {
        addMarker('user-marker', userCoordinates)
      }

      /* SELECTED GRAVE */
      if (!isEmpty(store.grave.selected)) {
        let markerCoordinates = null
        if (store.grave.selected.location) {
          markerCoordinates = [store.grave.selected.location.lng, store.grave.selected.location.lat]
        }
        if (store.map.route.length > 0) {
          const routeCoordinates = store.map.route.map(waypoint => {
            return [waypoint[0], waypoint[1]]
          })
          addRoute(routeCoordinates)
        }
        if (markerCoordinates) {
          addMarker('info-marker', markerCoordinates)
          if (store.map.focus) {
            map.current.flyTo({
              center: markerCoordinates,
              essential: false,
              zoom: maxZoom
            })
            store.map.focus = false
          }
        }
      }
      /* SELECTED THEME ROUTE */
      if (!isEmpty(store.themeRoute.selected)) {
        for (const [idx, waypoint] of store.themeRoute.selected.waypoints.entries()) {
          let pinClassName = 'important-sight-marker'
          if (idx === store.themeRoute.waypointIdx) {
            pinClassName = 'important-sight-selected-marker'
          }
          addMarker('important-sight-index', [waypoint.location.lng, waypoint.location.lat], idx)
          addMarker(pinClassName, [waypoint.location.lng, waypoint.location.lat], idx)
        }
        /* Route */
        const routeCoordinates = store.themeRoute.selected.route.map(waypoint => {
          return [waypoint[0], waypoint[1]]
        })
        addRoute(routeCoordinates)

        if (store.map.focus && store.themeRoute.waypointIdx !== null) {
          map.current.flyTo({
            center: [store.themeRoute.selected.waypoints[store.themeRoute.waypointIdx].location.lng, store.themeRoute.selected.waypoints[store.themeRoute.waypointIdx].location.lat],
            essential: true
          })
          store.map.focus = false
        }
      }

      if (!isEmpty(store.cemetery.selected)) {
        if (store.map.focus) {
          map.current.flyTo({
            center: [store.cemetery.selected.location.lng, store.cemetery.selected.location.lat],
            essential: true
          })
          store.map.focus = false
        }
      }
    }
  }, [store.map.renderTrigger])

  const observeMapChanges = () => {
    if (store.map.renderTrigger) {
      return null
    }
  }

  const getMapContainerStyle = () => {
    if (store.configuration.properties.integration) {
      return store.styles.list.mapContainerIntegration
    }
    if (store.navigation.device === 'desktop') {
      return store.styles.list.mapContainerDesktop
    }
    return store.styles.list.mapContainerMobile
  }

  return (
    <Box sx={store.styles.list.mapWrapper}>
      {observeMapChanges()}
      <Box sx={getMapContainerStyle()} ref={mapContainer}>
        {store.map.fetchingUserGeolocation &&
          <Box sx={store.styles.list.fetchUserGeolocationContainer}>
            <CircularProgress sx={store.styles.list.fetchUserGeolocationIcon} size={16} />
            <Typography sx={store.styles.list.fetchUserGeolocationLabel} variant="caption">
              {store.localization.language.common.searchingGeolocation}
            </Typography>
          </Box>
        }
        {store.navigation.device === 'desktop' &&
          <Tooltip placement="left" title={store.localization.language.common.updateGeolocation}>
            <Button
              sx={store.styles.list.refreshUserGeolocationButtonDesktop}
              color="primary"
              variant="contained"
              onClick={() => { store.map.fetchUserGeolocation() }}
            >
              <MyLocationIcon />
            </Button>
          </Tooltip>
        }
        <MapPrinting ref={map} />
      </Box>
    </Box>
  )
}

export default observer(Map)
