/* General */
import * as React from 'react'
import { useState, useEffect } from 'react'
import { observer } from 'mobx-react'

/* Components */
import { Grid } from '@mui/material'

/* Custom Components */
import Header from 'components/front-page/header'
import Body from 'components/front-page/body'
import Footer from 'components/front-page/footer'

/* Appearance */
import { ThemeProvider } from '@mui/material/styles'
import theme from 'components/front-page/theme'
import styles from 'components/front-page/styles'

function FrontPage () {
  const [width, setWidth] = useState('75%')

  const updateWidth = () => {
    window.innerWidth <= 1000 ? setWidth('100%') : setWidth('75%')
  }

  useEffect(() => {
    updateWidth()
  }, [])

  window.onresize = () => {
    updateWidth()
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container sx={styles.container}>
        <Header />
        <Body width={width} />
        <Footer />
      </Grid>
    </ThemeProvider>
  )
}

export default observer(FrontPage)
