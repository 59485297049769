/* General */
import * as React from 'react'
import { useContext } from 'react'
import { StoreContext } from 'stores/store'
import { observer } from 'mobx-react'

/* Components */
import {
  Box,
  Button,
  Typography
} from '@mui/material'

/* Custom Components */
import HelpList from 'components/help/help-list'
import HelpPageFeedback from 'components/help/help-page-feedback'
import HelpPageAccessibility from 'components/help/help-page-accessibility'
import HelpPagePrivacyPolicy from 'components/help/help-page-privacy-policy'
import HelpPageFAQ from 'components/help/help-page-faq'
import HelpPageUpdateNotes from 'components/help/help-page-update-notes'

const Help = () => {
  const store = useContext(StoreContext)

  const pages = {
    search: {
      buttonLabel: store.localization.language.help.button.grave,
      title: store.localization.language.help.search.title,
      component: (
        <HelpList instructions={store.localization.language.help.search.instructions} />
      )
    },
    cemetery: {
      buttonLabel: store.localization.language.help.button.cemetery,
      title: store.localization.language.help.cemetery.title,
      component: (
        <HelpList instructions={store.localization.language.help.cemetery.instructions} />
      )
    },
    themeRoute: {
      buttonLabel: store.localization.language.help.button.themeRoute,
      title: store.localization.language.help.themeRoute.title,
      component: (
        <HelpList instructions={store.localization.language.help.themeRoute.instructions} />
      )
    },
    faq: {
      buttonLabel: store.localization.language.help.button.faq,
      title: store.localization.language.help.faq.title,
      component: <HelpPageFAQ />
    },
    updateNotes: {
      buttonLabel: store.localization.language.help.button.updateNotes,
      title: store.localization.updateNotes.title,
      component: <HelpPageUpdateNotes />
    },
    feedback: {
      buttonLabel: store.localization.language.help.button.feedback,
      title: store.localization.language.help.feedback.title,
      component: <HelpPageFeedback />
    },
    accessibility: {
      buttonLabel: store.localization.language.help.button.accessibility,
      title: store.localization.language.help.button.accessibility,
      component: <HelpPageAccessibility />
    },
    privacyPolicy: {
      buttonLabel: store.localization.language.help.button.privacyPolicy,
      title: '',
      component: <HelpPagePrivacyPolicy />
    }
  }

  return (
    <Box sx={store.styles.list.help}>
      {!store.help.page &&
        <>
          <Typography
            sx={store.styles.list.helpHeader}
            variant={'h7'}
          >
            {store.localization.language.help.title}
          </Typography>
          <Box sx={store.styles.list.helpButtonContainer}>
            {Object.keys(pages).map((p, idx) => {
              return (
                <Button
                  key={idx}
                  sx={store.styles.list.helpActionButton}
                  onClick={() => { store.help.page = p }}
                  color="primary"
                  variant="contained"
                >
                  {pages[p].buttonLabel}
                </Button>
              )
            })}
            {store.navigation.device === 'desktop' &&
              <Button
                sx={store.styles.list.helpCloseButton}
                onClick={() => { store.help.show = false }}
                color="primary"
                variant="contained"
              >
                {store.localization.language.common.close}
              </Button>
            }
          </Box>

        </>
      }
      {store.help.page &&
        <Box sx={store.styles.list.helpPage}>
          <Typography
            sx={store.styles.list.helpHeader}
          >
            {pages[store.help.page].title}
          </Typography>
          {pages[store.help.page].component}
          {store.navigation.device === 'desktop' &&
            <Button
              sx={store.styles.list.helpBackButton}
              onClick={() => { store.help.page = null }}
              color="primary"
              variant="contained"
            >
              {store.localization.language.common.back}
            </Button>
          }
        </Box>
      }
    </Box>
  )
}

export default observer(Help)
