/* General */
import * as React from 'react'
import { useContext } from 'react'
import { StoreContext } from 'stores/store'
import { observer } from 'mobx-react'

/* Components */
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material'

/* Icons */
import DotIcon from '@mui/icons-material/FiberManualRecord'

const HelpList = (props) => {
  const store = useContext(StoreContext)
  const row = (instruction, idx) => {
    let listRow = store.styles.list.helpListOdd
    if (idx % 2 === 0) {
      listRow = store.styles.list.helpListEven
    }
    return (
      <ListItem sx={listRow} key={idx}>
        <ListItemIcon>
            <DotIcon sx={store.styles.list.dotIcon} />
        </ListItemIcon>
        <ListItemText
            sx={store.styles.list.helpListItemText}
            primary={instruction}
        />
      </ListItem>
    )
  }

  return (
      <Box sx={store.styles.list.helpListContainer}>
        <List sx={store.styles.list.helpList}>
           { props.instructions.map((instruction, idx) => row(instruction, idx)) }
       </List>
      </Box>
  )
}

export default observer(HelpList)
