/* General */
import * as React from 'react'
import { useContext } from 'react'
import { StoreContext } from 'stores/store'
import { observer } from 'mobx-react'

/* Components */
import {
  Box,
  Typography
} from '@mui/material'

/* Langugages */
import fi from 'localization/fi.json'
import sv from 'localization/sv.json'
import en from 'localization/en.json'

const ErrorConfiguration = () => {
  const store = useContext(StoreContext)
  const languages = [fi, sv, en]
  return (
    <Box sx={store.styles.list.errorConfigurationContainer}>
      {languages.map((language, idx) => {
        return (
          <Box sx={store.styles.list.errorConfigurationParagraph} key={idx}>
            {language.error.configuration.map((errorPhrase, idx) => {
              return (
                <Typography
                  key={idx}
                  variant='h6'
                >
                  {errorPhrase}
                </Typography>
              )
            })}
          </Box>
        )
      })}
    </Box>
  )
}

export default observer(ErrorConfiguration)
