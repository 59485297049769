import { fetchData } from 'utils/request-handler'
import { isEmpty } from 'lodash'

const StoreMap = (rootStore) => {
  return {
    renderTrigger: true,
    render: function () {
      this.renderTrigger = !this.renderTrigger
    },

    showData: false,
    set show (value) {
      this.showData = value
    },
    get show () {
      return this.showData
    },

    focusData: true,
    set focus (value) {
      this.focusData = value
    },
    get focus () {
      return this.focusData
    },

    routeData: [],
    set route (value) {
      this.routeData = value
    },
    get route () {
      return this.routeData
    },

    renderRouteToSelectedGrave: async function () {
      this.route = []
      if (!isEmpty(rootStore.grave.selected)) {
        if (rootStore.grave.selected.location) {
          if (this.geolocationDistance(this.userGeolocation, rootStore.grave.selected.location) <= 2.0) {
            const response = await fetchData(`${window.location.pathname}/api/route`, {
              cemeteryId: rootStore.grave.selected.cemeteryId,
              coordinates: [
                {
                  lng: rootStore.grave.selected.location.lng,
                  lat: rootStore.grave.selected.location.lat
                },
                {
                  lng: this.userGeolocation.lng,
                  lat: this.userGeolocation.lat
                }
              ]
            })
            if (response.success) {
              this.route = response.data
            }
          }
        }
        this.render()
      }
    },

    /* User Geolocation */
    toRad: function (number) {
      return number * Math.PI / 180
    },

    geolocationDistance: function (coordinate1, coordinate2) {
      if (coordinate1 && coordinate2) {
        const earthRadiusInKm = 6371
        const deltaLat = this.toRad(parseFloat(coordinate2.lat - coordinate1.lat))
        const deltaLng = this.toRad(parseFloat(coordinate2.lng - coordinate1.lng))
        const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
          Math.cos(this.toRad(parseFloat(coordinate1.lat))) * Math.cos(this.toRad(parseFloat(coordinate2.lat))) *
          Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2)
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
        const distanceInKm = earthRadiusInKm * c
        return distanceInKm
      }
      return Number.POSITIVE_INFINITY
    },

    userGeolocationData: {},
    set userGeolocation (value) {
      this.userGeolocationData = value
    },
    get userGeolocation () {
      return this.userGeolocationData
    },

    fetchingUserGeolocationData: false,
    set fetchingUserGeolocation (value) {
      this.fetchingUserGeolocationData = value
    },
    get fetchingUserGeolocation () {
      return this.fetchingUserGeolocationData
    },

    userGeolocationOptions: {
      enableHighAccuracy: true,
      timeout: 5000, // Milliseconds, 1000 = 1 second
      maximumAge: 0
    },

    userGeolocationError: function (err) {
      switch (err.code) {
        case 1:
          rootStore.navigation.showNotification(rootStore.localization.language.error.geolocationPermission, 'warning')
          break
        case 2:
          rootStore.navigation.showNotification(rootStore.localization.language.error.geolocationPosition, 'error')
          break
        case 3:
          // rootStore.navigation.showNotification(rootStore.localization.language.error.geolocationTimeout, 'error')
          break
        default:
          rootStore.navigation.showNotification(rootStore.localization.language.error.geolocationPosition, 'error')
      }
      rootStore.map.fetchingUserGeolocation = false
    },

    userGeolocationSuccess: function (position) {
      const accuracy = position.coords.accuracy
      const distanceInKm = rootStore.map.geolocationDistance(rootStore.map.userGeolocation, { lng: position.coords.longitude, lat: position.coords.latitude })
      const threshold = 10 / 1000 // meters / kilometers
      if (accuracy <= 150) {
        rootStore.map.userGeolocation = {
          lng: position.coords.longitude,
          lat: position.coords.latitude
        }
        if ((distanceInKm > threshold || isNaN(distanceInKm))) {
          rootStore.map.renderRouteToSelectedGrave()
        }
      }
      rootStore.map.fetchingUserGeolocation = false
      rootStore.map.render()
    },

    watch: null,
    fetchUserGeolocation: function () {
      const integration = rootStore.configuration.properties?.integration
      if (!integration || integration === false) {
        if (this.watch) {
          navigator.geolocation.clearWatch(this.watch)
        }
        rootStore.map.fetchingUserGeolocation = true
        this.watch = navigator.geolocation.watchPosition(this.userGeolocationSuccess, this.userGeolocationError, this.userGeolocationOptions)
      }
    },

    nearbyCemeteryData: false,
    set nearbyCemetery (value) {
      this.nearbyCemeteryData = value
    },
    get nearbyCemetery () {
      return this.nearbyCemeteryData
    }
  }
}

export default StoreMap
