/* General */
import * as React from 'react'
import { useContext } from 'react'
import { StoreContext } from 'stores/store'
import { observer } from 'mobx-react'

/* Components */
import {
  Box,
  Typography,
  IconButton
} from '@mui/material'

/* Custom Components */
import DetailList from 'components/navigation/detail-list'

/* Help */
import { isEmpty } from 'lodash'
import moment from 'moment'

/* Icons */
import CloseIcon from '@mui/icons-material/Close'

const DetailContainer = () => {
  const store = useContext(StoreContext)
  const styles = {
    containerRoot: {
      display: 'flex'
    },
    containerDesktop: {
      width: !isEmpty(store.grave.selected) || !isEmpty(store.themeRoute.selectedWaypoint) ? '400px' : '0px',
      transition: 'width 0.25s',
      maxHeight: 'calc(100vh - 100px)',
      overflow: 'auto'
    },
    containerMobile: {
      visibility: store.map.show ? 'visible' : 'hidden',
      position: 'fixed',
      bottom: store.navigation.showDetails ? '50px' : '-' + (store.navigation.detailsHeight - 50) + 'px',
      left: 0,
      zIndex: '1',
      width: '100%',
      borderRadius: '5px 5px 0px 0px',
      backgroundColor: 'white',
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.5)',
      transition: 'bottom 0.25s'
    },
    headerDesktop: {
      textAlign: 'center',
      padding: '10px'
    },
    headerMobile: {
      padding: '10px',
      borderRadius: '5px 5px 0px 0px',
      textAlign: 'center',
      color: 'white',
      backgroundColor: store.customization.getPalette().primary.main
    },
    closeButton: {
      position: 'absolute',
      right: '0',
      visibility: !isEmpty(store.grave.selected) || !isEmpty(store.themeRoute.selectedWaypoint) ? 'visible' : 'hidden'
    }
  }

  const getContent = () => {
    if (!isEmpty(store.grave.selected)) {
      const location = store.grave.selected.location
      const truncate = 10
      const graveLng = location.lng ? location.lng.toString().slice(0, -truncate) : '-'
      const graveLat = location.lat ? location.lat.toString().slice(0, -truncate) : '-'
      const graveLocation = graveLat + ', ' + graveLng
      const dateOfBirth = store.grave.selected.dateOfBirth ? moment(new Date(store.grave.selected.dateOfBirth)).format('DD.MM.YYYY') : '-'
      const dateOfDeath = store.grave.selected.dateOfDeath ? moment(new Date(store.grave.selected.dateOfDeath)).format('DD.MM.YYYY') : '-'
      const graveId = store.grave.selected.graveId ? store.grave.selected.graveId : '-'

      return (
        <>
          <Box sx={store.navigation.device === 'desktop' ? styles.headerDesktop : styles.headerMobile}>
            <Typography variant='button'>{store.grave.selected.name}</Typography>
          </Box>
          <DetailList
            list={[
              {
                title: store.localization.language.search.table.born,
                value: dateOfBirth
              },
              {
                title: store.localization.language.search.table.died,
                value: dateOfDeath
              },
              {
                title: store.localization.language.search.id,
                value: graveId
              },
              {
                title: store.localization.language.search.coordinates + ' (WGS84)',
                value: graveLocation
              }
            ]}
          />
        </>

      )
    }
    if (!isEmpty(store.themeRoute.selectedWaypoint)) {
      const infoString = store.themeRoute.selectedWaypoint.description ?? ''
      const infoArray = infoString.split('\n')

      const links = [
        {
          url: store.themeRoute.selectedWaypoint.link1,
          description: store.themeRoute.selectedWaypoint.link1Description
        },
        {
          url: store.themeRoute.selectedWaypoint.link2,
          description: store.themeRoute.selectedWaypoint.link2Description
        },
        {
          url: store.themeRoute.selectedWaypoint.link3,
          description: store.themeRoute.selectedWaypoint.link3Description
        }
      ]

      const additionalInformationString = store.themeRoute.selectedWaypoint.additionalInformation ?? ''
      const additionalInformationArray = additionalInformationString.split('\n')

      return (
        <>
          <Box sx={store.navigation.device === 'desktop' ? styles.headerDesktop : styles.headerMobile}>
            <Typography variant='button'>{store.themeRoute.selectedWaypoint.title}</Typography>
          </Box>
          <DetailList
            info={infoArray}
            links={links}
            additionalInformation={additionalInformationArray}
          />
        </>

      )
    }
    return null
  }

  const getDeviceContainer = () => {
    switch (store.navigation.device) {
      case 'desktop':
        return (
          <Box sx={styles.containerDesktop}>
            <IconButton
              size='small'
              sx={styles.closeButton}
              onClick={() => { store.navigation.closeDetails() }}
            >
              <CloseIcon fontSize='5px' />
            </IconButton>
            {getContent()}
          </Box>
        )
      case 'mobile':
        return (
          <Box sx={styles.containerMobile}>
            {getContent()}
          </Box>
        )
    }
  }

  return (
    <Box sx={styles.containerRoot}>
      {getDeviceContainer()}
    </Box>
  )
}

export default observer(DetailContainer)
