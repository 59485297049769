import { fetchData } from 'utils/request-handler'

const StoreGrave = (rootStore) => {
  return {
    metadataData: {},
    set metadata (value) {
      this.metadataData = value
    },
    get metadata () {
      return this.metadataData
    },
    fetchMetadata: async function () {
      try {
        const response = await fetchData(`${window.location.pathname}/api/graves/metadata`)
        if (response.success) {
          this.metadata = response.data
        }
      } catch (error) {
        console.error(error)
      }
    },

    listData: [],
    set list (value) {
      this.listData = value
    },
    get list () {
      return this.listData
    },

    selectedData: {},
    set selected (value) {
      this.selectedData = value
    },
    get selected () {
      return this.selectedData
    },

    fetchingData: false,
    set fetching (value) {
      this.fetchingData = value
    },
    get fetching () {
      return this.fetchingData
    },

    showSearchInfoData: false,
    set showSearchInfo (value) {
      this.showSearchInfoData = value
    },
    get showSearchInfo () {
      return this.showSearchInfoData
    },

    searchInfoData: '',
    set searchInfo (value) {
      this.searchInfoData = value
    },
    get searchInfo () {
      return this.searchInfoData
    },

    updateSearchInfo: function () {
      this.showSearchInfo = false
      if (this.list.length <= 0) {
        this.searchInfo = rootStore.localization.language.search.info.noResults
        this.showSearchInfo = true
      }
      if (this.list.length >= 200) {
        this.searchInfo = rootStore.localization.language.search.info.limit
        this.showSearchInfo = true
      }
    },

    fetchByUuid: async function (uuid) {
      try {
        const response = await fetchData(`${window.location.pathname}/api/graves/uuid/${uuid}`, {})
        if (response.success) {
          this.selected = response.data
          rootStore.map.focus = true
          rootStore.map.renderRouteToSelectedGrave()
        }
      } catch (error) {
        console.error(error)
      }
    },

    fetchByGraveId: async function (graveId) {
      try {
        const response = await fetchData(`${window.location.pathname}/api/graves/graveId/${graveId}`, {})
        if (response.success) {
          this.selected = response.data
          rootStore.map.focus = true
          rootStore.map.renderRouteToSelectedGrave()
        }
      } catch (error) {
        console.error(error)
      }
    },

    fetch: async function (params) {
      try {
        this.fetching = true
        this.searchInfo = rootStore.localization.language.common.searching
        this.list = []
        const response = await fetchData(`${window.location.pathname}/api/graves/search`, params)
        if (response.success) {
          this.list = response.data.map((row) => {
            if (row.location) {
              if (
                isNaN(row.location.lng) ||
                isNaN(row.location.lat) ||
                row.location.lng < parseFloat(rootStore.configuration.properties.mapbox.min.lng) ||
                row.location.lat < parseFloat(rootStore.configuration.properties.mapbox.min.lat) ||
                row.location.lng > parseFloat(rootStore.configuration.properties.mapbox.max.lng) ||
                row.location.lat > parseFloat(rootStore.configuration.properties.mapbox.max.lat)
              ) {
                row.disabled = true
                row.cemetery = rootStore.localization.language.search.table.missingLocation
              }
            } else {
              row.disabled = true
              row.cemetery = rootStore.localization.language.search.table.missingLocation
            }
            return row
          })
          this.updateSearchInfo()
        }
        this.fetching = false
      } catch (error) {
        this.searchInfo = rootStore.localization.language.search.info.error
        this.fetching = false
        console.error(error)
      }
    },

    sortKeyData: null,
    set sortKey (value) {
      this.sortKeyData = value
    },
    get sortKey () {
      return this.sortKeyData
    },

    sort: function (key) {
      if (!this.sortKey) {
        this.sortAscending(key)
        this.sortKey = key
      } else {
        if (key === this.sortKey) {
          this.sortDecending(key)
          this.sortKey = null
        } else {
          this.sortAscending(key)
          this.sortKey = key
        }
      }
    },

    sortAscending: function (key) {
      this.list.sort((a, b) => {
        const valueA = a[key].toLowerCase()
        const valueB = b[key].toLowerCase()
        return (valueA < valueB) ? -1 : (valueA > valueB) ? 1 : 0
      })
    },

    sortDecending: function (key) {
      this.list.sort((a, b) => {
        const valueA = a[key].toLowerCase()
        const valueB = b[key].toLowerCase()
        return (valueA > valueB) ? -1 : (valueA < valueB) ? 1 : 0
      })
    }
  }
}

export default StoreGrave
