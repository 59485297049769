import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#6fa335',
      dark: '#557d28',
      light: '#95ca5b',
      contrastText: '#ffffff'
    }
  }
})

export default theme
