/* General */
import * as React from 'react'
import { useContext } from 'react'
import { StoreContext } from 'stores/store'
import { observer } from 'mobx-react'

/* Components */
import {
  Box,
  Typography
} from '@mui/material'

/* Icons */
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import flagFI from 'assets/flag_fi.png'
import flagSV from 'assets/flag_sv.png'
import flagEN from 'assets/flag_en.png'

const Header = () => {
  const store = useContext(StoreContext)

  const changeLanguage = (value) => {
    store.localization.language = value
    store.grave.updateSearchInfo()
    store.map.render()
  }

  const toggleHelp = () => {
    store.help.show = !store.help.show
    if (store.help.show) {
      store.clear()
      store.map.render()
    } else {
      store.help.page = null
    }
  }

  return (
    <Box sx={store.styles.list.header}>
      <Typography sx={store.styles.list.headerLabel} variant="h6">
        Hautakartta
      </Typography>
      <Box sx={store.styles.list.flagContainer}>
        <Box sx={store.styles.list.flag} component={'img'} src={flagFI} onClick={() => changeLanguage('fi')} />
        <Box sx={store.styles.list.flag} component={'img'} src={flagSV} onClick={() => changeLanguage('sv')} />
        <Box sx={store.styles.list.flag} component={'img'} src={flagEN} onClick={() => changeLanguage('en')} />
      </Box>
      <HelpOutlineIcon sx={store.styles.list.helpButton} onClick={toggleHelp} />
    </Box>
  )
}

export default observer(Header)
