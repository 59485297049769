import { fetchData } from 'utils/request-handler'

const StoreCemetery = (rootStore) => {
  return {
    listData: [],
    set list (value) {
      this.listData = value
    },
    get list () {
      return this.listData
    },

    selectedData: {},
    set selected (value) {
      this.selectedData = value
    },
    get selected () {
      return this.selectedData
    },

    fetchingData: false,
    set fetching (value) {
      this.fetchingData = value
    },
    get fetching () {
      return this.fetchingData
    },

    fetch: async function () {
      try {
        const response = await fetchData(`${window.location.pathname}/api/cemetery`)

        if (response.success) {
          let sortedCemeteries = response.data.sort((a, b) => {
            const nameA = a.title.toLowerCase()
            const nameB = b.title.toLowerCase()
            return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0
          })

          const cemeteryOrderString = rootStore.configuration.properties.cemeteryOrder
          const cemeteryOrder = cemeteryOrderString ? cemeteryOrderString.split(',') : []
          cemeteryOrder.forEach((cemetery) => {
            const cemeteryData = cemetery.split(':')
            const cemeteryId = cemeteryData[0]
            const idx = parseInt(cemeteryData[1])
            const target = sortedCemeteries.find((g) => g.id === cemeteryId)
            sortedCemeteries = sortedCemeteries.filter((g) => g.id !== target.id)
            sortedCemeteries.splice(idx, 0, target)
          })
          this.list = sortedCemeteries
          for (const key in sortedCemeteries) {
            const cemetery = sortedCemeteries[key]
            const distance = rootStore.map.geolocationDistance(rootStore.map.userGeolocation, cemetery.location)
            if (distance <= 2.0) {
              rootStore.map.nearbyCemetery = true
              break
            }
          }
        } else {
          throw new Error(rootStore.localization.language.search.info.error)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}

export default StoreCemetery
