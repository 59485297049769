/* General */
import * as React from 'react'
import { useContext, useState, useEffect } from 'react'
import { StoreContext } from 'stores/store'
import { observer } from 'mobx-react'

/* Components */
import {
  Box,
  Button
} from '@mui/material'

const TabCemetery = () => {
  /* Variables */
  const store = useContext(StoreContext)
  const [selectedCemetery, setSelectedCemetery] = useState(store.cemetery.selected)

  /* Initialize */
  useEffect(() => {
    store.cemetery.fetch()
  }, [])

  /* Functions */
  const getCemeteries = () => {
    if (store.cemetery.list) {
      return store.cemetery.list.filter(cemetery => cemetery.location !== null)
    }
    return []
  }

  const selectCemetery = (event) => {
    const cemeteryId = event.currentTarget.value
    const selectedCemetery = getCemeteries().filter((cemetery) => cemetery.id === cemeteryId)
    setSelectedCemetery(selectedCemetery[0])
    store.cemetery.selected = selectedCemetery[0]

    if (store.navigation.device === 'mobile') {
      store.map.show = true
    }

    store.map.focus = true
    store.map.render()
  }

  return (
    <Box sx={store.styles.list.cemeteryContainer}>
      {getCemeteries().map((cemetery) => {
        return (
          <Button
            sx={selectedCemetery && cemetery.id === selectedCemetery.id ? store.styles.list.cemeteryButtonSelected : store.styles.list.cemeteryButton}
            key={cemetery.title}
            value={cemetery.id}
            onClick={(event) => selectCemetery(event, false)}>
            {cemetery.title}
          </Button>
        )
      })}
    </Box>
  )
}

export default observer(TabCemetery)
