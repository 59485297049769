import * as React from 'react'
import { createContext } from 'react'
import { useLocalObservable } from 'mobx-react'

/* Stores */
import StoreConfiguration from 'stores/store-configuration'
import StoreCustomization from 'stores/store-customization'
import StoreStyles from 'stores/store-styles'
import StoreLocalization from 'stores/store-localization'
import StoreNavigation from 'stores/store-navigation'
import StoreSearch from 'stores/store-search'
import StoreGrave from 'stores/store-grave'
import StoreCemetery from 'stores/store-cemetery'
import StoreMap from 'stores/store-map'
import StoreThemeRoute from 'stores/store-theme-route'
import StoreFeedback from 'stores/store-feedback'
import StoreHelp from 'stores/store-help'
import StorePrinting from 'stores/store-printing'

export const StoreContext = createContext()

export const StoreProvider = ({ children }) => {
  const store = useLocalObservable(() => ({
    clear: () => {
      store.search.graveName = ''
      store.search.cemeteryId = ''

      store.grave.list = []
      store.grave.selected = {}
      store.grave.fetching = false

      store.cemetery.list = []
      store.cemetery.selected = null
      store.cemetery.fetch()

      store.map.route = []
      store.map.userGeolocation = {}

      store.themeRoute.list = []
      store.themeRoute.selected = {}
      store.themeRoute.selectedWaypoint = null
      store.themeRoute.waypointIdx = null
      store.themeRoute.showWaypointInfo = false

      store.map.render()
    }
  }))

  store.configuration = StoreConfiguration(store)
  store.customization = StoreCustomization(store)
  store.styles = StoreStyles(store)
  store.localization = StoreLocalization(store)
  store.navigation = StoreNavigation(store)
  store.search = StoreSearch(store)
  store.grave = StoreGrave(store)
  store.cemetery = StoreCemetery(store)
  store.map = StoreMap(store)
  store.themeRoute = StoreThemeRoute(store)
  store.feedback = StoreFeedback(store)
  store.help = StoreHelp(store)
  store.printing = StorePrinting(store)

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  )
}
