import React from 'react'
import * as ReactDOMServer from 'react-dom/server'

const pinUserIcon = () => {
  return ReactDOMServer.renderToStaticMarkup(
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      version="1.1"
      id="svg1"
    >
      <g id="layer1">
      <path
          d="M 24,2.8691406 C 19.191961,2.953762 15.591947,4.4358634 12.193359,7.5703125 8.7947714,10.704761 7.0957031,14.914486 7.0957031,20.197266 c 0,3.521852 1.3993454,7.350104 4.1992189,11.488281 2.799872,4.138178 7.400354,8.40216 12.705078,11.464844 5.304724,-3.062684 9.905206,-7.326666 12.705078,-11.464844 2.799874,-4.138177 4.199219,-7.966429 4.199219,-11.488281 0,-5.28278 -1.699068,-9.492505 -5.097656,-12.6269535 C 32.408053,4.4358634 28.808039,2.953762 24,2.8691406 Z"
          id="path1"
          style={{ fill: '#61a72a', stroke: '#ffffff', strokeWidth: '2.5px', strokeDasharray: 'none', strokeOpacity: '1' }}
          transform="translate(0,0.89364315)"
        />
        <path
          d="m 18.131928,33.412126 3.349309,-16.866164 -2.153127,0.837327 v 4.067018 h -2.392364 v -5.622054 l 6.040718,-2.571792 q 0.418664,-0.179427 0.882184,-0.209331 0.463521,-0.0299 0.882185,0.119618 0.418663,0.149523 0.79247,0.418663 0.373807,0.269141 0.613043,0.687805 l 1.196182,1.913891 q 0.777518,1.255991 2.108271,2.063414 1.330752,0.807423 3.035311,0.807423 v 2.392363 q -2.093318,0 -3.738068,-0.867231 -1.64475,-0.867232 -2.811028,-2.212937 l -0.747613,3.678259 2.511982,2.392364 v 8.971364 H 25.309019 V 25.636944 L 22.797037,23.723053 20.64391,33.412126 Z M 25.90711,12.478943 q -0.98685,0 -1.689607,-0.702757 -0.702757,-0.702756 -0.702757,-1.689607 0,-0.9868498 0.702757,-1.6896067 0.702757,-0.7027566 1.689607,-0.7027566 0.98685,0 1.689607,0.7027566 0.702757,0.7027569 0.702757,1.6896067 0,0.986851 -0.702757,1.689607 -0.702757,0.702757 -1.689607,0.702757 z"
          id="path1-6"
          style={{ fill: '#ffffff', fillOpacity: '1', strokeWidth: 'stroke-width:0.0299045' }}
        />
      </g>
    </svg>
  )
}

export default pinUserIcon
