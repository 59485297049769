/* General */
import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { StoreProvider } from 'stores/store'
import { BrowserRouter as Router } from 'react-router-dom'

/* Custom Components */
import Hautakartta from 'hautakartta'
import FrontPage from 'front-page'

/* Error */
import { ErrorBoundary } from 'react-error-boundary'
import ErrorMessage from 'components/error/error-message'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <Router>
      <StoreProvider>
        <ErrorBoundary fallback={<ErrorMessage />}>
          {window.location.pathname === '/' ? <FrontPage /> : <Hautakartta />}
        </ErrorBoundary>
      </StoreProvider>
    </Router>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
