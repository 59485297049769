const StoreHelp = (rootStore) => {
  return {

    showData: false,
    set show (value) {
      this.showData = value
    },
    get show () {
      return this.showData
    },

    pageData: null,
    set page (value) {
      this.pageData = value
    },
    get page () {
      return this.pageData
    }
  }
}

export default StoreHelp
