/* General */
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { StoreContext } from 'stores/store'
import { observer } from 'mobx-react'
import styles from 'components/front-page/styles'
import { fetchData } from 'utils/request-handler'

/* Components */
import {
  Grid,
  Box,
  Typography,
  List,
  ListItem,
  Button
} from '@mui/material'

const Body = ({ width }) => {
  const store = useContext(StoreContext)

  const [congregations, setCongregations] = useState([])

  const fetchCongregations = async () => {
    const fetchedData = await fetchData('/api/congregations')
    if (fetchedData.success) {
      setCongregations(fetchedData.data)
    }
  }

  useEffect(() => {
    fetchCongregations()
  }, [])

  return (
    <Grid item sx={styles.body} width={width}>
      <Box sx={styles.banner}>
        <Typography sx={styles.infoLabel} variant='h4'>{store.localization.frontPage.infoLabel}</Typography>
      </Box>
      <Box sx={styles.infoContainer}>
        {store.localization.frontPage.infoParagraphs.map((paragraph, idx) => {
          return (
            <Typography key={idx} paragraph variant='body1'>
              {paragraph}
            </Typography>
          )
        })}
      </Box>
      <List sx={styles.congregationList}>
        {
          congregations.map((congregation, idx) => {
            return (
              <ListItem key={idx} sx={styles.congregationListItem}>
                <Button
                  color='primary'
                  variant='contained'
                  sx={styles.congregationButton}
                  onClick={() => {
                    window.open(window.location.href + congregation.url, '_blank')
                  }}
                >
                  {congregation.name}
                </Button>
              </ListItem>
            )
          })
        }
      </List>
    </Grid>
  )
}

export default observer(Body)
