import { fetchData } from 'utils/request-handler'

const StoreConfiguration = (rootStore) => {
  return {
    propertiesData: null,
    set properties (value) {
      this.propertiesData = value
    },
    get properties () {
      return this.propertiesData
    },

    missingData: undefined,
    set missing (value) {
      this.missingConfigurationData = value
    },
    get missing () {
      return this.missingConfigurationData
    },

    fetch: async function () {
      try {
        const response = await fetchData(`${window.location.pathname}/api/configuration`)
        if (response.success) {
          this.properties = response.data
        }
      } catch (error) {
        console.error(error)
        this.missing = true
      }
    }
  }
}

export default StoreConfiguration
