import React from 'react'
import * as ReactDOMServer from 'react-dom/server'

const pinIcon = (r, g, b) => {
  return ReactDOMServer.renderToStaticMarkup(
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      version="1.1"
      id="svg1"
    >
      <g id="layer1">
        <path
          d="M 24,2.8691406 C 19.191961,2.953762 15.591947,4.4358634 12.193359,7.5703125 8.7947714,10.704761 7.0957031,14.914486 7.0957031,20.197266 c 0,3.521852 1.3993454,7.350104 4.1992189,11.488281 2.799872,4.138178 7.400354,8.40216 12.705078,11.464844 5.304724,-3.062684 9.905206,-7.326666 12.705078,-11.464844 2.799874,-4.138177 4.199219,-7.966429 4.199219,-11.488281 0,-5.28278 -1.699068,-9.492505 -5.097656,-12.6269535 C 32.408053,4.4358634 28.808039,2.953762 24,2.8691406 Z"
          id="path1"
          style={{ fill: `rgb(${r}, ${g}, ${b})`, stroke: '#ffffff', strokeWidth: '2.5px', strokeDasharray: 'none', strokeOpacity: '1' }}
          transform="translate(0,0.89364315)"
        />
      </g>
    </svg>
  )
}

export default pinIcon
