/* General */
import * as React from 'react'
import { useContext } from 'react'
import { StoreContext } from 'stores/store'
import { observer } from 'mobx-react'

/* Components */
import {
  Box,
  Typography,
  Button
} from '@mui/material'

const ErrorMessage = () => {
  const store = useContext(StoreContext)
  const styles = {
    errorContainer: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    errorTitle: {
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '32px'
    },
    errorMessage: {
      textAlign: 'center',
      fontSize: '20px'
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '25px'
    },
    actionButton: {
      margin: '10px',
      width: '250px'
    }
  }

  const refreshPage = () => {
    window.location.reload(false)
  }

  const goHome = () => {
    window.location.href = window.location.origin + '/'
  }

  return (
    <Box sx={styles.errorContainer}>
      <Typography sx={styles.errorTitle}>
        {store.localization.language.error.general.title}
      </Typography>
      <Typography sx={styles.errorMessage}>
        {store.localization.language.error.general.message}
      </Typography>
      <Box sx={styles.buttonContainer}>
        <Button
          sx={styles.actionButton}
          variant="contained"
          onClick={refreshPage}
        >
          {store.localization.language.common.reloadPage}
        </Button>
        <Button
          sx={styles.actionButton}
          onClick={goHome}
        >
          {store.localization.language.common.goHome}
        </Button>
      </Box>
    </Box>
  )
}

export default observer(ErrorMessage)
