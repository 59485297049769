/* General */
import * as React from 'react'
import { observer } from 'mobx-react'
import { useContext, useRef, useEffect } from 'react'
import { StoreContext } from 'stores/store'

/* Components */
import {
  Box,
  Typography,
  Link,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material'

const DetailList = (props) => {
  const store = useContext(StoreContext)
  const listRef = useRef()
  const styles = {
    containerDesktop: {
      maxHeight: 'calc(100vh - 100px)',
      overflow: 'auto'
    },
    containerMobile: {
      minHeight: 'calc(50vh - 50px)',
      maxHeight: 'calc(50vh - 50px)',
      overflow: 'auto'
    },
    rowTitle: {
      fontWeight: 'bold',
      borderBottom: 'none'
    },
    rowValue: {
      textAlign: 'right',
      borderBottom: 'none'
    },
    infoText: {
      whiteSpace: 'pre-wrap',
      padding: '10px'
    }
  }

  useEffect(() => {
    store.navigation.detailsHeight = listRef.current?.offsetHeight
  }, [listRef.current])

  return (
    <Box ref={listRef} sx={store.navigation.device === 'desktop' ? styles.containerDesktop : styles.containerMobile}>
      <TableContainer>
        <Table>
          <TableBody>
            {props.list && props.list.map((row, idx) => {
              return (
                <TableRow key={idx}>
                  <TableCell sx={styles.rowTitle}>
                    {row.title}
                  </TableCell>
                  <TableCell sx={styles.rowValue}>
                    {row.value}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {
        props.info?.map((info, idx) => {
          return (
            <Typography key={idx} sx={styles.infoText}>
              {info}
            </Typography>
          )
        })
      }
      {
        props.links?.map((link) => {
          if (link.url) {
            return (
              <>
                <Typography
                  sx={styles.infoText}
                >
                  {link.description ?? ''}
                </Typography>
                <Link
                  sx={styles.infoText}
                  href={link.url}
                  target='_blank'
                >
                  {link.url}
                </Link>
              </>
            )
          }
          return null
        })
      }
      {
        props.additionalInformation?.map((additionalInformation, idx) => {
          if (additionalInformation) {
            return (
              <Typography key={idx} sx={styles.infoText}>
                {additionalInformation}
              </Typography>
            )
          }
          return null
        })
      }
    </Box>
  )
}

export default observer(DetailList)
