import { fetchData } from 'utils/request-handler'

import fi from 'localization/fi.json'
import sv from 'localization/sv.json'
import en from 'localization/en.json'
import frontPageFi from 'localization/front-page-fi'
import frontPageSv from 'localization/front-page-sv'
import frontPageEn from 'localization/front-page-en'
import accessibilityFi from 'localization/accessibility_fi.json'
import accessibilitySv from 'localization/accessibility_sv.json'
import accessibilityEn from 'localization/accessibility_en.json'
import privacyPolicyFi from 'localization/privacy_policy_fi.json'
import privacyPolicySv from 'localization/privacy_policy_sv.json'
import privacyPolicyEn from 'localization/privacy_policy_en.json'
import updateNotesFi from 'localization/update_notes_fi.json'
import updateNotesSv from 'localization/update_notes_sv.json'
import updateNotesEn from 'localization/update_notes_en.json'

const StoreLocalization = (rootStore) => {
  return {
    languageData: fi,
    set language (value) {
      switch (value) {
        case 'fi':
          this.languageData = fi
          this.frontPage = frontPageFi
          this.accessibilityStatement = accessibilityFi
          this.privacyPolicy = privacyPolicyFi
          this.infoText = this.infoData?.fi
          this.updateNotes = updateNotesFi
          break
        case 'sv':
          this.languageData = sv
          this.frontPage = frontPageSv
          this.accessibilityStatement = accessibilitySv
          this.privacyPolicy = privacyPolicySv
          this.infoText = this.infoData?.sv
          this.updateNotes = updateNotesSv
          break
        case 'en':
          this.languageData = en
          this.frontPage = frontPageEn
          this.accessibilityStatement = accessibilityEn
          this.privacyPolicy = privacyPolicyEn
          this.infoText = this.infoData?.en
          this.updateNotes = updateNotesEn
          break
      }
    },
    get language () {
      return this.languageData
    },

    frontPageData: frontPageFi,
    set frontPage (value) {
      this.frontPageData = value
    },
    get frontPage () {
      return this.frontPageData
    },

    accessibilityStatementData: accessibilityFi,
    set accessibilityStatement (value) {
      this.accessibilityStatementData = value
    },
    get accessibilityStatement () {
      return this.accessibilityStatementData
    },

    privacyPolicyData: privacyPolicyFi,
    set privacyPolicy (value) {
      this.privacyPolicyData = value
    },
    get privacyPolicy () {
      return this.privacyPolicyData
    },

    updateNotesData: updateNotesFi,
    set updateNotes (value) {
      this.updateNotesData = value
    },
    get updateNotes () {
      return this.updateNotesData
    },

    infoTextData: null,
    set infoText (value) {
      this.infoTextData = value
    },
    get infoText () {
      return this.infoTextData
    },

    infoDataData: null,
    set infoData (value) {
      this.infoDataData = value
    },
    get infoData () {
      return this.infoDataData
    },

    fetchInfoData: async function () {
      try {
        const response = await fetchData(`${window.location.pathname}/api/info`)
        if (response.success) {
          this.infoData = response.data
          this.infoText = response.data.fi
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}

export default StoreLocalization
