/* General */
import * as React from 'react'
import { useContext } from 'react'
import { observer } from 'mobx-react'
import { StoreContext } from 'stores/store'

/* Components */
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material'

/* Icons */
import DotIcon from '@mui/icons-material/FiberManualRecord'

const HelpPageUpdateNotes = () => {
  const store = useContext(StoreContext)
  return (
    <List sx={store.styles.list.updateNotesList}>
      {store.localization.updateNotes.list.map((note, idx) => {
        return (
          <ListItem sx={store.styles.list.updateNotesRow} key={idx}>
            <ListItemIcon>
              <DotIcon sx={store.styles.list.updateNotesDotIcon} />
            </ListItemIcon>
            <ListItemText
              sx={store.styles.list.updateNotesListItemText}
              primary={note}
            />
          </ListItem>
        )
      })}
    </List>
  )
}

export default observer(HelpPageUpdateNotes)
