/* General */
import * as React from 'react'
import { useContext } from 'react'
import { StoreContext } from 'stores/store'
import { observer } from 'mobx-react'

/* Components */
import {
  Box,
  Tab,
  Tabs
} from '@mui/material'

/* Custom Components */
import TabContent from 'components/navigation/tab-content'

/* Tabs */
import TabSearch from 'components/tabs/tab-search'
import TabCemetery from 'components/tabs/tab-cemetery'
import TabThemeRoute from 'components/tabs/tab-theme-route'
import TabInfo from 'components/tabs/tab-info'

/* Icons */
import SearchIcon from '@mui/icons-material/Search'
import CemeteryIcon from '@mui/icons-material/Church'
import ThemeRouteIcon from '@mui/icons-material/Route'
import InfoIcon from '@mui/icons-material/Info'

const TabContainer = () => {
  const store = useContext(StoreContext)

  const changeTab = (event, value) => {
    store.clear()
    store.navigation.tab = value
  }

  return (
        <Box sx={store.navigation.device === 'desktop' ? store.styles.list.menuContainerDesktop : store.styles.list.menuContainerMobile}>
            <Tabs
                value={store.navigation.tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={changeTab}
                centered
            >
                {store.configuration.properties.services.search &&
                    <Tab
                        sx={store.styles.list.tab}
                        value={'tab-search'}
                        label={store.localization.language.tabs.search}
                        icon={<SearchIcon sx={store.styles.list.tabIcon} />}
                    />
                }
                {store.configuration.properties.services.cemeteries &&
                    <Tab
                        sx={store.styles.list.tab}
                        value={'tab-cemeteries'}
                        label={store.localization.language.tabs.cemeteries}
                        icon={<CemeteryIcon sx={store.styles.list.tabIcon} />}
                    />
                }
                {store.configuration.properties.services.themeRoutes &&
                    <Tab
                        sx={store.styles.list.tab}
                        value={'tab-theme-routes'}
                        label={store.localization.language.tabs.themeRoutes}
                        icon={<ThemeRouteIcon sx={store.styles.list.tabIcon} />}
                    />
                }
                {store.configuration.properties.services.info &&
                    <Tab
                        sx={store.styles.list.tab}
                        value={'tab-info'}
                        label={store.localization.language.tabs.info}
                        icon={<InfoIcon sx={store.styles.list.tabIcon} />}
                    />
                }
            </Tabs>
            <TabContent value={store.navigation.tab} index={'tab-search'}>
                <TabSearch />
            </TabContent>
            <TabContent value={store.navigation.tab} index={'tab-cemeteries'}>
                <TabCemetery />
            </TabContent>
            <TabContent value={store.navigation.tab} index={'tab-theme-routes'}>
                <TabThemeRoute />
            </TabContent>
            <TabContent value={store.navigation.tab} index={'tab-info'}>
                <TabInfo />
            </TabContent>
        </Box>
  )
}

export default observer(TabContainer)
