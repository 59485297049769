import { fetchData } from 'utils/request-handler'

const StorePrinting = (rootStore) => {
  return {
    overlayData: '',
    set overlay (value) {
      this.overlayData = value
    },
    get overlay () {
      return this.overlayData
    },
    basemapSuccessData: false,
    set basemapSuccess (value) {
      this.basemapSuccessData = value
    },
    get basemapSuccess () {
      return this.basemapSuccessData
    },

    basemapImgUrlData: '',
    set basemapImgUrl (value) {
      this.basemapImgUrlData = value
    },
    get basemapImgUrl () {
      return this.basemapImgUrlData
    },

    fetchBasemap: async function (params) {
      const style = rootStore.configuration.properties.mapbox.style.split('/').pop()
      try {
        const response = params.bbox
          ? await fetchData(`https://api.mapbox.com/styles/v1/geometrixdev/${style}/static${params.route}/${params.bbox}/${params.width}x${params.height}@2x?access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`, '', 'arraybuffer')
          : await fetchData(`https://api.mapbox.com/styles/v1/geometrixdev/${style}/static${params.route}/${params.longitude},${params.latitude},${params.zoom}/${params.width}x${params.height}@2x?access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`, '', 'arraybuffer')
        const blob = new Blob([response.data], { type: 'image/png' })
        this.basemapImgUrl = URL.createObjectURL(blob)
        this.basemapSuccess = response.success
      } catch (error) {
        console.error(error)
      }
    },
    fetchingData: false,
    set fetching (value) {
      this.fetchingData = value
    },
    get fetching () {
      return this.fetchingData
    }
  }
}

export default StorePrinting
