/* General */
import * as React from 'react'
import { useContext } from 'react'
import { StoreContext } from 'stores/store'
import { observer } from 'mobx-react'

/* Components */
import {
  Snackbar,
  Alert
} from '@mui/material'

const Notification = () => {
  const store = useContext(StoreContext)

  const closeNotification = () => {
    store.navigation.showingNotification = false
  }

  return (
        <Snackbar
            sx={store.styles.list.notification}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            open={store.navigation.showingNotification}
            autoHideDuration={5000}
            onClose={closeNotification}
        >
            <Alert
                elevation={4}
                variant="filled"
                onClose={closeNotification}
                severity={store.navigation.notification.severity}
            >
                {store.navigation.notification.message}
            </Alert>
        </Snackbar>
  )
}

export default observer(Notification)
