/* General */
import * as React from 'react'
import { useContext, useRef, useState } from 'react'
import { StoreContext } from 'stores/store'
import { observer } from 'mobx-react'

/* Components */
import {
  Box,
  CircularProgress,
  TextField,
  Button,
  FormControl,
  InputLabel,
  NativeSelect,
  Typography
} from '@mui/material'

/* Icons */
import NegativeIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import NegativeNeutralIcon from '@mui/icons-material/SentimentDissatisfied'
import PositiveNeutralIcon from '@mui/icons-material/SentimentSatisfied'
import PositiveIcon from '@mui/icons-material/SentimentSatisfiedAlt'

const HelpPageFeedback = () => {
  const store = useContext(StoreContext)
  const textRef = useRef()
  const contactRef = useRef()

  const [typeError, setTypeError] = useState(true)
  const [textFieldError, setTextFieldError] = useState(true)

  const Status = {
    UNSENT: 0,
    SENT: 1
  }

  const [status, setStatus] = useState(Status.UNSENT)

  const Score = {
    NONE: 0,
    NEGATIVE: 1,
    NEGATIVE_NEUTRAL: 2,
    POSITIVE_NEUTRAL: 3,
    POSITIVE: 4
  }

  const [score, setScore] = useState(Score.NONE)

  const Type = {
    NONE: 0,
    DECEASED: 1,
    APP: 2,
    OTHER: 3
  }

  const [type, setType] = useState(Type.NONE)

  const changeType = (event) => {
    const value = parseInt(event.target.value)
    setType(value)
    setTypeError(value === Type.NONE)
  }

  const validateTextField = () => {
    setTextFieldError(textRef.current.value.length <= 0)
  }

  const send = async () => {
    if (!typeError && !textFieldError) {
      await store.feedback.sendEmail({
        message: textRef.current.value,
        contact: contactRef.current.value,
        score: score,
        type: type
      })
      setStatus(Status.SENT)
    }
  }

  return (
    <>
      {store.feedback.sendingEmail
        ? <Box sx={store.styles.list.spinnerContainer}>
          <CircularProgress sx={store.styles.list.spinner} size={64} color="primary" />
        </Box>
        : <Box>
          {status === Status.UNSENT
            ? <Box sx={store.styles.list.formContainer}>
              <FormControl sx={store.styles.list.typeSelect}>
                <InputLabel htmlFor="type">{store.localization.language.help.feedback.type.title}</InputLabel>
                <NativeSelect id="type" value={type} error={typeError} onChange={changeType}>
                  <option value={Type.NONE} />
                  <option value={Type.DECEASED}>{store.localization.language.help.feedback.type.deceased}</option>
                  <option value={Type.APP}>{store.localization.language.help.feedback.type.app}</option>
                  <option value={Type.OTHER}>{store.localization.language.help.feedback.type.other}</option>
                </NativeSelect>
              </FormControl>
              <TextField
                sx={store.styles.list.feedbackTextField}
                variant="outlined"
                label={store.localization.language.help.feedback.message}
                multiline
                rows={Math.round(screen.height / 150)}
                inputRef={textRef}
                onInput={validateTextField}
                error={textFieldError}
                disabled={store.feedback.sendingEmail}
              />
              <TextField
                sx={store.styles.list.feedbackTextField}
                variant="outlined"
                label={store.localization.language.help.feedback.contact}
                multiline
                rows={Math.round(screen.height / 500)}
                inputRef={contactRef}
                disabled={store.feedback.sendingEmail}
              />
              <Box sx={store.styles.list.smileyContainer}>
                <NegativeIcon
                  sx={store.styles.list.smiley}
                  onClick={() => setScore(Score.NEGATIVE)}
                  color={score === Score.NEGATIVE ? 'primary' : 'disabled'}
                />
                <NegativeNeutralIcon
                  sx={store.styles.list.smiley}
                  onClick={() => setScore(Score.NEGATIVE_NEUTRAL)}
                  color={score === Score.NEGATIVE_NEUTRAL ? 'primary' : 'disabled'}
                />
                <PositiveNeutralIcon
                  sx={store.styles.list.smiley}
                  onClick={() => setScore(Score.POSITIVE_NEUTRAL)}
                  color={score === Score.POSITIVE_NEUTRAL ? 'primary' : 'disabled'}
                />
                <PositiveIcon
                  sx={store.styles.list.smiley}
                  onClick={() => setScore(Score.POSITIVE)}
                  color={score === Score.POSITIVE ? 'primary' : 'disabled'}
                />
              </Box>
              <Button
                sx={store.styles.list.sendButton}
                color="primary"
                variant="contained"
                onClick={send}
                disabled={typeError || textFieldError}
              >
                {store.localization.language.help.feedback.send}
              </Button>
            </Box>
            : <Typography variant={'h6'}>
                {store.feedback.emailSuccess ? store.localization.language.help.feedback.success : store.localization.language.error.feedback}
              </Typography>
          }
        </Box>
      }
    </>
  )
}

export default observer(HelpPageFeedback)
