/* General */
import * as React from 'react'
import { useContext, useEffect } from 'react'
import { StoreContext } from 'stores/store'
import { observer } from 'mobx-react'

/* Components */
import {
  Box
} from '@mui/material'

/* Custom Components */
import Notification from 'components/navigation/notification'
import Help from 'components/help/help'
import Map from 'components/navigation/map'
import TabContainer from 'components/navigation/tab-container'

import DetailContainer from './detail-container'

const Body = () => {
  const store = useContext(StoreContext)

  useEffect(() => {
    resizeEvent()
  }, [])

  const resizeEvent = () => {
    store.navigation.device = getDevice()
    store.map.show = getShowMap()
    store.map.render()
  }

  window.onresize = resizeEvent

  const getDevice = () => {
    return window.innerWidth < store.navigation.mobileSize ? 'mobile' : 'desktop'
  }

  const getShowMap = () => {
    return store.navigation.device === 'desktop'
  }

  const getBodyContent = () => {
    if (!store.configuration.properties) {
      return (<></>)
    }
    if (store.help.show) {
      return (<Help />)
    }
    return (
      <>
        <TabContainer />
        <Box sx={{ ...store.styles.list.mapComponentContainer, visibility: store.map.show ? 'visible' : 'hidden' }}>
          <Map />
        </Box>
        <DetailContainer />
      </>
    )
  }

  return (
    <Box sx={store.navigation.device === 'desktop' ? store.styles.list.bodyDesktop : store.styles.list.bodyMobile}>
      <Notification />
      {getBodyContent()}
    </Box>
  )
}

export default observer(Body)
