/* General */
import * as React from 'react'
import { useContext, useEffect } from 'react'
import { StoreContext } from 'stores/store'
import { observer } from 'mobx-react'

/* Custom Components */
import ErrorConfiguration from 'components/error/error-configuration'

/* Pages */
import PageMain from 'components/pages/page-main'
import PageIntegration from 'components/pages/page-integration'

/* Appearance */
import './hautakartta.css'

const Hautakartta = () => {
  const store = useContext(StoreContext)

  useEffect(() => {
    store.configuration.fetch()
  }, [])

  useEffect(() => {
    if (store.configuration.properties) {
      store.styles.initializeList()
      store.localization.fetchInfoData()
      store.map.fetchUserGeolocation()
      store.cemetery.fetch()
      store.grave.fetchMetadata()
      document.title = store.configuration.properties?.name
        ? 'Hautakartta - ' + store.configuration.properties?.name
        : 'Hautakartta'
    }
  }, [store.configuration.properties])

  const getApplication = () => {
    if (store.configuration.properties && store.customization) {
      if (store.configuration.properties.integration) {
        return (<PageIntegration />)
      }
      return (<PageMain />)
    }
    if (store.configuration.missing !== undefined) {
      return (<ErrorConfiguration />)
    }
    return (<></>)
  }

  return getApplication()
}

export default observer(Hautakartta)
