import axios from 'axios'

const validate = (response) => {
  if (response.status === 200 || response.status === 204) {
    return {
      success: true,
      data: response.data
    }
  }
  return {
    success: false
  }
}

const fetchData = async (url, params, responseType) => {
  if (!url.startsWith('https://api.mapbox.com')) {
    url = url.replace('//', '/')
  }
  try {
    const config = {
      url,
      params,
      method: 'GET',
      responseType
    }
    const response = await axios.get(url, config)
    return validate(response)
  } catch (error) {
    console.error(error)
  }
}

const postData = async (url, params) => {
  url = url.replace('//', '/')
  try {
    const response = await axios({
      url,
      data: params,
      method: 'POST'
    })
    return validate(response)
  } catch (error) {
    console.error(error)
  }
}

export default {}
export { fetchData, postData }
