/* General */
import * as React from 'react'
import { useContext } from 'react'
import { StoreContext } from 'stores/store'
import { observer } from 'mobx-react'
import styles from 'components/front-page/styles'

/* Components */
import { Grid, Box, Typography } from '@mui/material'

/* Icons */
import flagFI from 'assets/flag_fi.png'
import flagSV from 'assets/flag_sv.png'
import flagEN from 'assets/flag_en.png'

const Header = () => {
  const store = useContext(StoreContext)

  return (
    <Grid xs={12} item sx={styles.header}>
      <Typography variant='button'>{store.localization.frontPage.title}</Typography>
      <Grid sx={styles.flagContainer}>
        <Box sx={styles.flag} component='img' src={flagFI} onClick={() => { store.localization.language = 'fi' }} />
        <Box sx={styles.flag} component='img' src={flagSV} onClick={() => { store.localization.language = 'sv' }} />
        <Box sx={styles.flag} component='img' src={flagEN} onClick={() => { store.localization.language = 'en' }} />
      </Grid>
    </Grid>
  )
}

export default observer(Header)
