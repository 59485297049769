const StoreStyles = (rootStore) => {
  return {
    listData: {},
    set list (value) {
      this.listData = value
    },
    get list () {
      return this.listData
    },
    initializeList: function () {
      const palette = rootStore.customization.getPalette()
      this.list = {
        header: {
          width: '100%',
          height: '50px',
          minHeight: '50px',
          paddingRight: '10px',
          paddingLeft: '10px',
          display: 'flex',
          position: 'fixed',
          top: '0',
          backgroundColor: palette.primary.main,
          color: '#ffffff',
          boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
          zIndex: '100'
        },
        headerLabel: {
          fontWeight: 'bold',
          margin: 'auto'
        },
        bodyDesktop: {
          display: 'flex',
          marginTop: '50px',
          overflow: 'hidden'
        },
        bodyMobile: {
          display: 'block',
          marginTop: '50px'
        },
        footer: {
          width: '100%',
          height: '50px',
          position: 'fixed',
          bottom: '0',
          color: '#ffffff',
          backgroundColor: palette.primary.main,
          fontWeight: 'bold',
          zIndex: '100'
        },
        footerContentDesktop: {
          paddingLeft: '10px',
          textAlign: 'left'
        },
        footerContentMobile: {
          textAlign: 'center'
        },
        footerButtonMobile: {
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          '& button': {
            padding: '5px',
            fontSize: '12px',
            fontWeight: '1000',
            opacity: '1.0',
            minHeight: '0px'
          }
        },
        footerUpdateInfo: {
          float: 'right',
          paddingRight: '15px'
        },
        notification: {
          top: '60px !important'
        },
        menuContainerDesktop: {
          width: '400px',
          height: 'calc(100vh - 100px)',
          overflow: 'auto'
        },
        menuContainerMobile: {
          width: '100vw'
        },
        mapComponentContainer: {
          display: 'flex',
          flex: '1'
        },
        mapWrapper: {
          flex: '1'
        },
        mapContainerDesktop: {
          height: 'calc(100vh - 100px)',
          overflow: 'hidden',
          position: 'relative',
          right: '0',
          top: '0'
        },
        mapContainerMobile: {
          width: '100vw',
          height: 'calc(100vh - 50px)',
          overflow: 'hidden',
          position: 'fixed',
          margin: 'auto',
          top: '0',
          marginTop: '50px',
          zIndex: '1'
        },
        mapContainerIntegration: {
          width: '100vw',
          height: '100vh'
        },
        flagContainer: {
          flex: '1',
          margin: 'auto',
          float: 'right',
          textAlign: 'right'
        },
        flag: {
          cursor: 'pointer',
          width: '32px',
          height: '24px',
          marginRight: '10px',
          marginLeft: '10px',
          boxShadow: '2px 2px 4px black',
          '&:hover': {
            filter: 'brightness(75%)'
          },
          '&:active': {
            filter: 'brightness(125%)'
          }
        },
        helpButton: {
          float: 'right',
          textAlign: 'right',
          cursor: 'pointer',
          width: '32px',
          height: '32px',
          paddingRight: '20px',
          margin: 'auto',
          '&:hover': {
            filter: 'brightness(75%)'
          },
          '&:active': {
            filter: 'brightness(125%)'
          }
        },
        tab: {
          minWidth: '20%',
          width: '20%',
          marginRight: '10px',
          marginLeft: '10px',
          fontSize: '10px',
          overflow: 'visible',
          whiteSpace: 'nowrap',
          fontWeight: '600',
          letterSpacing: '0.05em'
        },
        tabIcon: {
          fontSize: '20px'
        },
        searchContainer: {
          padding: '10px'
        },
        searchClearButton: {
          fontSize: '20px'
        },
        searchName: {
          width: '100%',
          marginBottom: '5px',
          marginTop: '10px',
          '& label': {
            left: '-15px'
          }
        },
        searchCemetery: {
          width: '100%',
          '& label': {
            left: '-15px'
          }
        },
        searchButton: {
          width: '100%',
          marginTop: '20px'
        },
        searchInfoLabel: {
          color: '#707070',
          fontFamily: 'sans-serif',
          fontWeight: '400',
          fontSize: '1rem',
          whiteSpace: 'pre-wrap',
          textAlign: 'center',
          marginTop: '50px',
          height: '32px'
        },
        searchInfoSpinner: {
          marginTop: '20px',
          textAlign: 'center'
        },
        cemeteryContainer: {
          display: 'flex',
          flexDirection: 'column',
          margin: 'auto',
          padding: '25px'
        },
        cemeteryButton: {
          color: '#FFFFFF',
          backgroundColor: palette.primary.main,
          textTransform: 'initial',
          margin: '5px',
          '&:hover': {
            backgroundColor: palette.primary.light
          }
        },
        cemeteryButtonSelected: {
          color: '#FFFFFF',
          backgroundColor: palette.primary.dark,
          textTransform: 'initial',
          margin: '5px',
          '&:hover': {
            backgroundColor: palette.primary.light
          }
        },
        themeRouteContainer: {
          padding: '10px',
          overflow: 'auto'
        },
        themeRouteCemeterySelect: {
          width: '100%',
          marginBottom: '20px',
          '& label': {
            left: '-15px'
          }
        },
        themeRouteSelect: {
          width: '100%',
          marginBottom: '10px',
          '& label': {
            left: '-15px'
          }
        },
        themeRouteTitle: {
          fontWeight: 'bold',
          marginBottom: '10px'
        },
        themeRouteWaypointInfo: {
          wordWrap: 'breakWord',
          whiteSpace: 'pre-line',
          overflow: 'auto'
        },
        themeRouteWaypointInfoContainer: {
          height: 'calc(100vh - 250px)',
          overflow: 'auto',
          whiteSpace: 'pre-wrap'
        },
        themeRouteWaypointInfoText: {
          textAlign: 'left',
          padding: '20px',
          whiteSpace: 'pre-line',
          marginBottom: '20px'
        },
        themeRouteList: {
          maxWidth: '800px',
          maxHeight: '440px',
          overflow: 'auto',
          padding: '0px !important'
        },
        themeRouteListItemText: {
          paddingTop: '2px',
          paddingBottom: '2px'
        },
        themeRouteListRow: {
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: palette.primary.dark,
            color: '#ffffff'
          },
          '&:focus': {
            backgroundColor: palette.primary.dark,
            color: '#ffffff'
          }
        },
        themeRouteListRowSelected: {
          backgroundColor: palette.primary.main,
          color: '#ffffff'
        },
        themeRouteListRowDisabled: {
          backgroundColor: palette.disabled.main,
          color: '#646464'
        },
        themeRouteLine: {
          backgroundColor: '#e0e0e0',
          color: '#e0e0e0',
          height: '1px'
        },
        themeRouteMobileButtonContainer: {
          width: '100%',
          position: 'absolute',
          textAlign: 'center',
          bottom: '0',
          left: '0',
          right: '0',
          marginLeft: 'auto',
          marginRight: 'auto'
        },
        themeRouteMobileButton: {
          marginBottom: '5px'
        },
        infoContainer: {
          margin: '10px'
        },
        infoHeader: {
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '16px',
          paddingTop: '10px'
        },
        infoParagraph: {
          fontSize: '14px',
          margin: '10px',
          textAlign: 'left'
        },
        closeInfoButton: {
          width: '90%',
          maxWidth: '512px',
          position: 'fixed',
          top: '85%',
          left: '50%',
          transform: 'translate(-50%, -95%)',
          zIndex: '1'
        },
        refreshUserGeolocationButtonDesktop: {
          width: '32px',
          height: '32px',
          position: 'absolute',
          right: '1px',
          top: '100px',
          marginRight: '8px',
          minWidth: '0px',
          zIndex: '1000'
        },
        printMapButtonDesktop: {
          width: '32px',
          height: '32px',
          position: 'absolute',
          right: '1px',
          top: '135px',
          marginRight: '8px',
          minWidth: '0px',
          zIndex: '1000'
        },
        fetchUserGeolocationContainer: {
          backgroundColor: '#ffffff',
          position: 'relative',
          display: 'inline-flex',
          justifyContent: 'center',
          alignContent: 'center',
          height: '24px',
          margin: '10px',
          borderRadius: '5px',
          boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.5)',
          zIndex: '10'
        },
        fetchUserGeolocationIcon: {
          marginTop: '4px',
          marginLeft: '4px'
        },
        fetchUserGeolocationLabel: {
          margin: 'auto',
          padding: '4px'
        },
        listContainer: {
          display: 'flex',
          justifyContent: 'center'
        },
        list: {
          width: '100%',
          maxWidth: '800px'
        },
        listItemText: {
          fontSize: '2.5vh',
          paddingLeft: '10px',
          paddingRight: '10px',
          paddingTop: '20px',
          paddingBottom: '20px'
        },
        dotIcon: {
          width: '16px',
          paddingLeft: '10px'
        },
        rowEven: {
          backgroundColor: 'rgba(0, 0, 0, 0.1)'
        },
        rowOdd: {
          backgroundColor: 'rgba(0, 0, 0, 0.2)'
        },
        spinnerContainer: {
          position: 'relative',
          height: '55vh',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center'
        },
        formContainer: {
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: '125px'
        },
        typeSelect: {
          width: '90%',
          maxWidth: '512px',
          marginBottom: '10px',
          '& label': {
            marginLeft: '-15px'
          }
        },
        feedbackTextField: {
          width: '90%',
          maxWidth: '512px',
          marginBottom: '10px'
        },
        smileyContainer: {
          width: '100%'
        },
        smiley: {
          fontSize: '64px',
          paddingTop: '10px',
          paddingBottom: '10px',
          paddingLeft: '8px',
          paddingRight: '8px'
        },
        sendButton: {
          width: '90%',
          maxWidth: '512px',
          marginTop: '16px'
        },
        accessibilityContainer: {
          width: '100%',
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center'
        },
        accessibilityTextContainer: {
          textAlign: 'left',
          marginBottom: '128px',
          maxWidth: '525px',
          wordBreak: 'break-word',
          padding: '10px'
        },
        privacyPolicyContainer: {
          width: '80%',
          margin: 'auto',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center'
        },
        privacyPolicyTextContainer: {
          textAlign: 'left',
          marginBottom: '128px',
          maxWidth: '525px'
        },
        faqContainer: {
          width: '80%',
          margin: 'auto',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: '50px'
        },
        updateNotesList: {
          maxWidth: '500px',
          margin: 'auto'
        },
        updateNotesListItemText: {
          fontSize: '2.5vh',
          padding: '2px'
        },
        updateNotesRow: {
          padding: '2px'
        },
        updateNotesDotIcon: {
          width: '16px',
          paddingLeft: '10px'
        },
        help: {
          position: 'absolute',
          backgroundColor: palette.neutral.main,
          width: '100%',
          textAlign: 'center',
          marginTop: '20px'
        },
        helpPage: {
          marginLeft: '10px',
          marginRight: '10px'
        },
        helpHeader: {
          textAlign: 'center',
          fontWeight: 'bold',
          marginBottom: '10px'
        },
        helpTextContainer: {
          textAlign: 'left',
          marginBottom: '20px',
          maxWidth: '525px'
        },
        helpButtonContainer: {
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center'
        },
        helpActionButton: {
          width: '90%',
          maxWidth: '512px',
          marginTop: '20px'
        },
        helpCloseButton: {
          width: '90%',
          maxWidth: '512px',
          marginTop: '75px',
          marginBottom: '75px'
        },
        helpBackButton: {
          width: '90%',
          maxWidth: '512px',
          position: 'fixed',
          bottom: '75px',
          left: '50%',
          transform: 'translate(-50%, 0)'
        },
        helpInstruction: {
          fontSize: '2.5vh',
          padding: '10px',
          margin: '20px',
          textAlign: 'center'
        },
        helpListContainer: {
          width: '100%',
          paddingBottom: '125px'
        },
        helpList: {
          maxWidth: '512px',
          margin: 'auto',
          overflow: 'auto'
        },
        helpListEven: {
          backgroundColor: 'rgba(0, 0, 0, 0.1)'
        },
        helpListOdd: {
          backgroundColor: 'rgba(0, 0, 0, 0.2)'
        },
        tableScrollDesktop: {
          minHeight: '100px',
          marginTop: '20px',
          overflow: 'scroll'
        },
        tableScrollMobile: {
          minHeight: '100px',
          marginTop: '20px',
          overflow: 'scroll',
          position: 'relative',
          zIndex: '1'
        },
        tableScrollHeaderLink: {
          color: palette.primary.main,
          textDecoration: 'underline',
          cursor: 'pointer',
          userSelect: 'none',
          fontWeight: 'bold',
          fontSize: '12px',
          '&:hover': {
            filter: 'brightness(75%)'
          },
          '&:active': {
            filter: 'brightness(125%)'
          }
        },
        tableScrollHeader: {
          color: palette.neutral.dark,
          fontWeight: 'bold',
          fontSize: '12px'
        },
        tableScrollRow: {
          '&:hover': {
            backgroundColor: palette.primary.dark,
            color: '#ffffff'
          },
          '&:focus': {
            backgroundColor: palette.primary.dark,
            color: '#ffffff'
          }
        },
        tableScrollRowSelected: {
          backgroundColor: palette.primary.main,
          color: '#ffffff'
        },
        tableScrollRowDisabled: {
          backgroundColor: palette.disabled.main,
          color: '#646464'
        },
        tableScrollCell: {
          color: 'inherit',
          paddingLeft: '5px',
          paddingRight: '5px',
          paddingTop: '10px',
          paddingBottom: '10px'
        },
        errorConfigurationContainer: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        },
        errorConfigurationParagraph: {
          width: '75%',
          minWidth: '350px',
          maxWidth: '750px',
          padding: '10px'
        }
      }
    }
  }
}

export default StoreStyles
