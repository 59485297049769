/* General */
import * as React from 'react'
import { useContext } from 'react'
import { StoreContext } from 'stores/store'
import { observer } from 'mobx-react'

/* Components */
import {
  Box,
  Typography
} from '@mui/material'

const HelpPagePrivacyPolicy = (props) => {
  const store = useContext(StoreContext)

  return (
    <Box sx={store.styles.list.privacyPolicyContainer}>
      <Typography
        sx={store.styles.list.helpHeader}
        variant={'h7'}
      >
        {store.localization.privacyPolicy.label}
      </Typography>
      <Box sx={store.styles.list.privacyPolicyTextContainer}>
        <Typography paragraph>
          {store.localization.privacyPolicy.policy}
        </Typography>
      </Box>
    </Box>
  )
}

export default observer(HelpPagePrivacyPolicy)
