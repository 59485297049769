/* General */
import * as React from 'react'
import { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { StoreContext } from 'stores/store'
import { observer } from 'mobx-react'

/* Custom Components */
import Map from 'components/navigation/map'

/* Help */
import { isEmpty } from 'lodash'

const PageIntegration = () => {
  const store = useContext(StoreContext)
  const search = useLocation().search
  const urlParams = new URLSearchParams(search)

  const fetchGrave = async () => {
    const graveId = urlParams.get('grave')

    if (!isEmpty(graveId)) {
      await store.grave.fetchByGraveId(graveId)
    }
  }

  useEffect(() => {
    fetchGrave()
  }, [])

  return (
    <Map />
  )
}

export default observer(PageIntegration)
