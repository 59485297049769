/* General */
import * as React from 'react'
import { useContext } from 'react'
import { StoreContext } from 'stores/store'
import { observer } from 'mobx-react'

/* Components */
import {
  Box,
  Grid,
  Tab
} from '@mui/material'

/* Icons */
import BackIcon from '@mui/icons-material/ArrowBack'
import UpdateLocationIcon from '@mui/icons-material/NotListedLocation'
import CloseIcon from '@mui/icons-material/Close'
import InfoIcon from '@mui/icons-material/Info'

/* Help */
import { isEmpty } from 'lodash'
import moment from 'moment'

const Footer = () => {
  const store = useContext(StoreContext)

  const desktop = () => {
    const gravesUpdated = moment(new Date(store.grave.metadata.timestamp)).format('DD.MM.YYYY')
    return (
      <Box sx={store.styles.list.footer}>
        <Box sx={store.styles.list.footerContentDesktop}>
          <Box component="p">
            <Box component="span">{store.configuration.properties?.name}</Box>
            <Box sx={store.styles.list.footerUpdateInfo} component="span">{store.localization.language.common.dataUpdated} {gravesUpdated}</Box>
          </Box>
        </Box>
      </Box>
    )
  }

  const mobile = () => {
    if (store.help.page) {
      return (
        <Box sx={store.styles.list.footer}>
          <Grid container>
            <Grid sx={store.styles.list.footerButtonMobile} item xs={12}>
              <Tab
                icon={<BackIcon />}
                label={store.localization.language.common.back}
                onClick={() => { store.help.page = null }}
              />
            </Grid>
          </Grid>
        </Box>
      )
    }
    if (store.help.show) {
      return (
        <Box sx={store.styles.list.footer}>
          <Grid container>
            <Grid sx={store.styles.list.footerButtonMobile} item xs={12}>
              <Tab
                icon={<CloseIcon />}
                label={store.localization.language.common.close}
                onClick={() => { store.help.show = false }}
              />
            </Grid>
          </Grid>
        </Box>
      )
    }
    if (store.map.show) {
      const showInfoButton = !isEmpty(store.grave.selected) || !isEmpty(store.themeRoute.selectedWaypoint)
      return (
        <Box sx={store.styles.list.footer}>
          <Grid container>
            <Grid sx={store.styles.list.footerButtonMobile} item xs={showInfoButton ? 4 : 6}>
              <Tab
                icon={<BackIcon />}
                label={store.localization.language.common.back}
                onClick={() => {
                  store.map.show = false
                  store.navigation.closeDetails()
                }}
              />
            </Grid>
            {showInfoButton &&
              <Grid sx={store.styles.list.footerButtonMobile} item xs={4}>
                <Tab
                  icon={<InfoIcon />}
                  onClick={() => { store.navigation.showDetails = !store.navigation.showDetails }}
                  label={!store.navigation.showDetails
                    ? store.localization.language.common.showDetails
                    : store.localization.language.common.hideDetails
                  }
                />
              </Grid>
            }
            <Grid sx={store.styles.list.footerButtonMobile} item xs={showInfoButton ? 4 : 6}>
              <Tab
                icon={<UpdateLocationIcon />}
                label={store.localization.language.common.updateGeolocation}
                onClick={() => { store.map.fetchUserGeolocation() }}
              />
            </Grid>
          </Grid>
        </Box>
      )
    }
    return (<></>)
  }

  return store.navigation.device === 'desktop' ? desktop() : mobile()
}

export default observer(Footer)
