/* Help */
import { createTheme } from '@mui/material/styles'

const StoreCustomization = (rootStore) => {
  return {
    getRGB: function (offset) {
      const r = rootStore.configuration.properties?.color.red + offset
      const g = rootStore.configuration.properties?.color.green + offset
      const b = rootStore.configuration.properties?.color.blue + offset
      return `rgb(${r},${g},${b})`
    },
    getPalette: function () {
      const main = this.getRGB(0)
      const light = this.getRGB(20)
      const dark = this.getRGB(-40)
      return {
        primary: {
          main: main,
          light: light,
          dark: dark,
          contrastText: '#ffffff'
        },
        neutral: {
          main: '#f5f7f3'
        },
        disabled: {
          main: '#d4d4d4'
        }
      }
    },
    getTheme: function () {
      const paletteData = this.getPalette()
      return createTheme({
        palette: paletteData
      })
    }
  }
}

export default StoreCustomization
