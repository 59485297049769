import theme from 'components/front-page/theme'
import bannerImage from 'assets/banner.png'

const styles = {
  container: {
    display: 'block',
    backgroundColor: theme.palette.primary.dark
  },
  header: {
    height: '50px',
    fontSize: '1.26rem',
    paddingRight: '10px',
    paddingLeft: '10px',
    display: 'flex',
    alignItems: 'center',
    color: '#ffffff',
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)'
  },
  body: {
    height: 'calc(100vh - 100px)',
    display: 'block',
    margin: 'auto',
    overflow: 'auto',
    padding: '20px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)'
  },
  footer: {
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)'
  },
  flagContainer: {
    float: 'right',
    textAlign: 'right',
    width: '100%'
  },
  flag: {
    cursor: 'pointer',
    width: '32px',
    height: '24px',
    marginRight: '10px',
    marginLeft: '10px',
    boxShadow: '2px 2px 4px black',
    '&:hover': {
      filter: 'brightness(75%)'
    },
    '&:active': {
      filter: 'brightness(125%)'
    }
  },
  banner: {
    width: '100%',
    height: '20%',
    maxWidth: '1024px',
    margin: 'auto',
    marginBottom: '10px',
    color: '#ffffff',
    outline: '2px solid ' + theme.palette.primary.dark,
    backgroundImage: 'url(' + bannerImage + ')',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    textShadow: '-1px -1px 2px rgba(0, 0, 0, 0.25), 1px -1px 2px rgba(0, 0, 0, 0.25), -1px 1px 2px rgba(0, 0, 0, 0.25), 1px 1px 2px rgba(0, 0, 0, 0.25)'
  },
  infoLabel: {
    fontWeight: 'bold'
  },
  infoContainer: {
    width: '100%',
    maxWidth: '1024px',
    margin: 'auto'
  },
  congregationList: {
    paddingBottom: '25px'
  },
  congregationListItem: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: '0px',
    paddingRight: '0px'
  },
  congregationButton: {
    width: '100%',
    maxWidth: '1024px',
    height: '64px'
  }
}

export default styles
