/* General */
import * as React from 'react'
import { useContext } from 'react'
import { StoreContext } from 'stores/store'
import { observer } from 'mobx-react'

/* Components */
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Paper
} from '@mui/material'

const TableScroll = (props) => {
  const store = useContext(StoreContext)

  const selectGrave = (row) => {
    if (store.navigation.device === 'mobile') {
      store.map.show = true
    }
    store.map.focus = true
    store.grave.selected = row
    store.map.renderRouteToSelectedGrave()
  }

  const selectWaypoint = (waypoint) => {
    store.map.focus = true
    store.themeRoute.showWaypointInfo = true
    store.themeRoute.waypointIdx = waypoint.idx
    store.themeRoute.selectedWaypoint = waypoint
    store.map.render()

    if (store.navigation.device === 'mobile') {
      store.map.show = true
    }
  }

  // TODO: Automatically fill rest of the remaining space with flexbox.
  const getProperties = () => {
    switch (props.type) {
      case 'grave':
        return {
          height: store.navigation.device === 'desktop' ? 'calc(100vh - 385px)' : 'calc(100vh - 335px)',
          data: store.grave.list,
          callback: selectGrave,
          selected: store.grave.selected,
          showIndex: false,
          headers: [
            {
              key: 'name',
              name: `${store.localization.language.search.table.name} (${store.grave.list.length})`,
              callback: () => { store.grave.sort('name') }
            },
            {
              key: 'yearOfBirth',
              name: store.localization.language.search.table.born,
              callback: () => { store.grave.sort('yearOfBirth') }
            },
            {
              key: 'yearOfDeath',
              name: store.localization.language.search.table.died,
              callback: () => { store.grave.sort('yearOfDeath') }
            },
            {
              key: 'cemetery',
              name: store.localization.language.search.table.cemetery,
              callback: () => { store.grave.sort('cemetery') }
            }
          ]
        }
      case 'themeRoute':
        return {
          height: store.navigation.device === 'desktop' ? 'calc(100vh - 305px)' : 'calc(100vh - 255px)',
          data: store.themeRoute.selected.waypoints,
          callback: selectWaypoint,
          selected: store.themeRoute.selected.waypoints.find(waypoint => waypoint.idx === store.themeRoute.waypointIdx),
          showIndex: true,
          headers: [
            {
              key: 'title',
              name: store.localization.language.search.name
            }
          ]
        }
    }
  }

  const getRowClass = (row) => {
    if (row === getProperties().selected) {
      return store.styles.list.tableScrollRowSelected
    }
    if (row.disabled) {
      return store.styles.list.tableScrollRowDisabled
    }
    return store.styles.list.tableScrollRow
  }

  const selectRow = (row) => {
    if (!row.disabled) {
      getProperties().callback(row)
    }
  }

  const styleClass = store.navigation.device === 'desktop' ? store.styles.list.tableScrollDesktop : store.styles.list.tableScrollMobile
  styleClass.height = getProperties().height

  return (
    <TableContainer sx={styleClass} component={Paper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {getProperties().headers.map((header, idx) => {
              return (
                <TableCell sx={store.styles.list.tableScrollCell} key={idx}>
                  <Typography
                    sx={header.callback ? store.styles.list.tableScrollHeaderLink : store.styles.list.tableScrollHeader}
                    onClick={header.callback}
                  >
                    {header.name}
                  </Typography>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {getProperties().data.map((row, idx) => (
            <TableRow sx={getRowClass(row)} onClick={() => { selectRow(row) }} key={idx}>
              {getProperties().headers.map((header, headerIdx) => {
                return (
                  <TableCell
                    sx={store.styles.list.tableScrollCell}
                    key={headerIdx}
                    align="left"
                  >
                    {getProperties().showIndex ? `${idx + 1}. ` : ''}
                    {row[header.key] ? row[header.key] : '-'}
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default observer(TableScroll)
