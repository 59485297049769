/* General */
import * as React from 'react'
import { useContext, useState, useEffect, createRef } from 'react'
import { StoreContext } from 'stores/store'
import { observer } from 'mobx-react'

/* Components */
import {
  Box,
  Button,
  IconButton,
  CircularProgress,
  FormControl,
  NativeSelect,
  Input,
  InputLabel,
  InputAdornment
} from '@mui/material'

/* Custom Components */
import TableScroll from 'components/navigation/table-scroll'

/* Icons */
import ClearIcon from '@mui/icons-material/Clear'

/* Help */
import { isEmpty } from 'lodash'

const TabSearch = () => {
  const store = useContext(StoreContext)

  /* Variables */
  const [cemeteryId, setCemeteryId] = useState(store.search.cemeteryId)

  /* References */
  const nameRef = createRef()

  /* Initialize */
  useEffect(() => {
    setCemeteryId(store.search.cemeteryId)
    if (store.cemetery.list.length === 0) {
      store.cemetery.fetch()
    }
  }, [store.grave.selected])

  /* Functions */
  const handleName = (event) => {
    store.search.graveName = nameRef.current.value
  }

  const changeCemetery = (event) => {
    setCemeteryId(event.target.value)
    store.search.cemeteryId = event.target.value
  }

  const fetchGrave = async () => {
    const params = {}

    const name = nameRef.current.value
    if (!isEmpty(name)) {
      params.name = name
    }

    if (!isEmpty(cemeteryId)) {
      params.cemeteryId = cemeteryId
    }
    await store.grave.fetch(params)
  }

  return (
    <Box sx={store.styles.list.searchContainer}>
      <FormControl sx={store.styles.list.searchName}>
        <InputLabel htmlFor="searchName">{store.localization.language.search.name}</InputLabel>
        <Input
          id="searchName"
          label={store.localization.language.search.name}
          sx={store.styles.list.searchName}
          type="text"
          placeholder={store.localization.language.search.firstName + ' ' + store.localization.language.search.lastName}
          value={store.search.graveName}
          inputRef={nameRef}
          onInput={handleName}
          endAdornment={
            <InputAdornment
              position="end"
              sx={{ visibility: store.search.graveName ? 'visible' : 'hidden' }}
            >
              <IconButton
                onClick={() => { store.search.graveName = '' }}
              >
                <ClearIcon sx={store.styles.list.searchClearButton} />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl sx={store.styles.list.searchCemetery}>
        <InputLabel htmlFor="cemetery">{store.localization.language.search.cemetery}</InputLabel>
        <NativeSelect id="cemetery" value={cemeteryId} onChange={changeCemetery}>
          <option value={null}></option>
          {store.cemetery.list.length > 0 && store.cemetery.list.map((cemetery, idx) => {
            return <option key={idx} value={cemetery.id}>{cemetery.title}</option>
          })}
        </NativeSelect>
      </FormControl>
      <Button
        sx={store.styles.list.searchButton}
        color="primary"
        variant="contained"
        onClick={fetchGrave}
      >
        {store.localization.language.common.search}
      </Button>
      {store.grave.showSearchInfo &&
        <Box component={'p'} sx={store.styles.list.searchInfoLabel}>{store.grave.searchInfo}</Box>
      }
      {store.grave.fetching &&
        <Box sx={store.styles.list.searchInfoSpinner}>
          <CircularProgress size={64} />
        </Box>
      }
      {store.grave.list.length > 0 && store.grave.list.length < 200 &&
        <TableScroll type="grave" />
      }
    </Box>
  )
}

export default observer(TabSearch)
