const StoreNavigation = (rootStore) => {
  return {
    mobileSize: 1000,
    deviceData: 'mobile',
    set device (value) {
      this.deviceData = value
    },
    get device () {
      return this.deviceData
    },

    showingNotificationData: false,
    set showingNotification (value) {
      this.showingNotificationData = value
    },
    get showingNotification () {
      return this.showingNotificationData
    },

    notificationData: {},
    set notification (value) {
      this.notificationData = value
    },
    get notification () {
      return this.notificationData
    },

    showNotification: function (message, severity) {
      this.notification = {
        message: message,
        severity: severity
      }
      this.showingNotification = true
    },

    showDetailsData: false,
    set showDetails (value) {
      this.showDetailsData = value
    },
    get showDetails () {
      return this.showDetailsData
    },

    detailsHeightData: 0,
    set detailsHeight (value) {
      this.detailsHeightData = value
    },
    get detailsHeight () {
      return this.detailsHeightData
    },

    closeDetails: function () {
      switch (this.device) {
        case 'desktop':
          rootStore.grave.selected = null
          rootStore.themeRoute.selectedWaypoint = null
          rootStore.themeRoute.waypointIdx = null
          rootStore.themeRoute.showWaypointInfo = false
          rootStore.map.render()
          break
        case 'mobile':
          rootStore.navigation.showDetails = false
          break
      }
    },

    tabData: 'tab-search',
    set tab (value) {
      this.tabData = value
    },
    get tab () {
      return this.tabData
    }
  }
}

export default StoreNavigation
