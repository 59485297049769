/* General */
import * as React from 'react'
import { observer } from 'mobx-react'

/* Components */
import {
  Box
} from '@mui/material'

const TabContent = (props) => {
  const { children, value, index } = props
  return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
        >
            {value === index && children}
        </Box>
  )
}

export default observer(TabContent)
