const StoreSearch = (rootStore) => {
  return {
    graveNameData: '',
    set graveName (value) {
      this.graveNameData = value
    },
    get graveName () {
      return this.graveNameData
    },
    cemeteryIdData: '',
    set cemeteryId (value) {
      this.cemeteryIdData = value
    },
    get cemeteryId () {
      return this.cemeteryIdData
    }
  }
}

export default StoreSearch
