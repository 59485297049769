/* General */
import * as React from 'react'
import { useContext } from 'react'
import { StoreContext } from 'stores/store'
import { observer } from 'mobx-react'

/* Components */
import {
  Box,
  Typography
} from '@mui/material'

const HelpPageFAQ = () => {
  const store = useContext(StoreContext)

  return (
    <Box sx={store.styles.list.faqContainer}>
      {store.localization.language.help.faq.content.map((content) => {
        return (
          <>
            <Typography
              sx={store.styles.list.helpHeader}
              variant={'h7'}
            >
              {content.title}
            </Typography>
            <Box sx={store.styles.list.helpTextContainer}>
              <Typography
                paragraph
              >
                {content.text}
              </Typography>
            </Box>
          </>
        )
      })}
    </Box>
  )
}

export default observer(HelpPageFAQ)
