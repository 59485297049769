/* General */
import * as React from 'react'
import { observer } from 'mobx-react'
import styles from 'components/front-page/styles'

/* Components */
import { Grid } from '@mui/material'

const Footer = () => {
  return (
    <Grid item xs={12} sx={styles.footer} />
  )
}

export default observer(Footer)
