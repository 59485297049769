/* General */
import * as React from 'react'
import { useContext } from 'react'
import { observer } from 'mobx-react'
import { StoreContext } from 'stores/store'

/* Components */
import { Box, Typography } from '@mui/material'

/* Help */
import moment from 'moment'

const TabInfo = () => {
  const store = useContext(StoreContext)
  const getParagraphs = () => {
    return store.localization.infoText.paragraphs.map((m, idx) => <Box component={'p'} key={idx} sx={store.styles.list.paragraph}>{m}</Box>)
  }
  return (
    <Box sx={store.styles.list.infoContainer}>
      <Typography
        variant={'h5'}
        sx={store.styles.list.infoHeader}
      >
        {store.localization.infoText.label}
      </Typography>
      {getParagraphs()}
      <Typography>
        {store.localization.language.common.dataUpdated} {moment(new Date(store.grave.metadata.timestamp)).format('DD.MM.YYYY')}
      </Typography>
    </Box>
  )
}

export default observer(TabInfo)
